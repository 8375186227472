:root {
  --background-logomarca: #0062B1;
  --background-menu: #0062B1;
  --fonte_menu_lateral: #FFFFFF;
  --fonte_grupo_menu_lateral: #FFFFFF;
  --fonte_titulo_abas: #0062B1;
  --filtro_logo: ""
}

:focus {
  outline: -webkit-focus-ring-color auto 0px;
}

a:hover {
  text-decoration: none;
}

button {
  border: none;
}

button:hover {
  opacity: 0.8 !important;
  cursor: pointer !important;
}

button:focus {
  outline: -webkit-focus-ring-color auto 0px;
}

body {
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 0.8em;
  font-display: swap;
  overflow-x: hidden;
}

.hdv-menubar {
  background-color: white !important;
  color: #111;
  height: 65px;
  /*padding: 10px 32px;*/
  padding-left: 90px !important;
  padding-right: 32px;
  margin-top: 10px !important;
  padding-top: 60px !important;
}

.menu-bar-dropdown-item {
  font-size: 14px;
  cursor: pointer;
  display: flex;
  width: 100%;
  align-items: center;
}

.hdv-background-header {
  width: 100%;
  height: 200px;
  background-color: #fff;
}

.hdv-app-container {
  z-index: 10;
  position: absolute;
  top: 0px;
  width: 100%;
}

.hdv-body-app {
  width: 100%;
  margin-top: 5px;
  padding-left: 110px;
  padding-right: 60px;
  background-color: white;
}

.mini-modal {
  padding: 10px !important;
  border-radius: 15px !important;
  border: 3px solid rgb(236, 236, 236) !important;
  box-shadow: rgb(236, 236, 236) 5px 5px 0px -1px !important;
}

.fixed {
  float: left;
  background-color: var(--background-menu);
  display: block;
  position: fixed;
  z-index: 9999;
  height: 100%;
  display: flex;
  overflow: hidden;
  border-radius: 0 20px 20px 0;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
}

.fixed-content {
  opacity: 1;
  transition: 0.3s;
  background-color: var(--background-menu);
}

.fixed-toggle {
  margin-left: -250px;
  opacity: 0 !important;
  z-index: -1000;
  transition: margin-left 0.3s;
}

.fixed .hdv-module-toggle {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--fonte_menu_lateral) !important;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.20);
  width: 50px;
  z-index: 1000;
}

.fixed .hdv-module-toggle .toggler {
  position: relative;
}

.fixed .hdv-module-toggle .toggler small {
  display: none;
  background-color: rgb(41, 43, 53);
  position: absolute;
  left: 50px;
  z-index: 100;
  font-size: 14px;
  top: 18px;
  user-select: none;
  color: white;
  border-radius: 4px;
  padding: 2px 8px;
}

.fixed .hdv-module-toggle .toggler i {
  margin: 6px 0;
  border: none;
  color: var(--fonte_menu_lateral) !important;
  padding: 10px 9px;
  border-left: 3px solid transparent;
}

.modulo-selecionado {
  border-left: 3px solid white !important;
}

.fixed .hdv-module-toggle .toggler:hover {
  border-left: 3px solid rgba(255, 255, 255, 0.753);
  cursor: pointer;
}

.fixed .hdv-module-toggle .toggler:hover small {
  display: block;
}

.fluid {
  margin-left: 250px;
  height: 100%;
  transition: 0.3s;
}

.fluid-toggle {
  margin-left: 0px !important;
  transition: 0.3s;
}

.hdv-logomarca {
  height: 200px;
  background-color: var(--background-logomarca);
  display: flex;
  align-items: center;
}

.hdv-bloco-informacao {
  border-radius: 4px;
}

.hdv-bloco-informacao-page-no-rgrid {
  background-color: #f2f2f2;
  padding: 10px 20px 10px 20px;
}

.hdv-bloco-top-space {
  margin-top: 20px;
}

.hdv-menu-grupo {
  font-size: 11px;
  margin: 8px 0px 2px 5px;
  font-weight: 600;
  color: var(--fonte_grupo_menu_lateral);
}

.hdv-menu {
  font-size: 0.9rem;
  padding: 5px 4px 5px 10px;
  color: var(--fonte_menu_lateral);
  margin-right: 4px;
  cursor: pointer;
  border-left: 5px solid transparent;
}

.hdv-menu-parent {
  color: #fff;
  padding-right: 3px;
}

.hdv-menu-parent:hover {
  text-decoration: none;
  color: #424242;
}

.hdv-menu-item {
  margin: 5px 0px 5px 25px;
  color: var(--fonte_menu_lateral)
}

.fa-20px {
  font-size: 20px !important;
}

.fa-30px {
  font-size: 30px !important;
}

.hdv-menu-image-top {
  line-height: 45px;
  /* margin-right: 15px; */
  color: #979699 !important;
}

.hdv-user-image {
  width: 40px;
}

.hdv-image-round {
  background-color: white;
  border-radius: 40px;
  margin-bottom: 4px;
  display: block;
  border: 4px solid;
}

.hdv-logomarca img {
  max-width: 200px;
  margin: 0 auto;
  height: fit-content;
  filter: var(--filtro_logo);
}

.hdv-menu-selected {
  background-color: rgba(0, 0, 0, 0.16);
  border-radius: 0 20px 20px 0;
  border-left: 5px solid #ffffff;
}

.hdv-menu:hover {
  background-color: rgba(0, 0, 0, 0.12);
  border-radius: 0 20px 20px 0;
  border-left: 5px solid #ffffff;
}

.fa-12px {
  font-size: 12px;
}

.hdv-menu-item-grupo {
  display: none;
}

.hdv-menu-item-grupo-open {
  display: block;
}

.hdv-caret-menu {
  float: right;
  margin-top: 5px;
  margin-right: 10px;
}

.hdv-info-bloco {
  padding: 10px;
  height: 100px;
  border-radius: 5px;
}

.hdv-title-bloco {
  color: #fff;
  font-size: 0.9rem;
  font-weight: 300;
}

.hdv-body-bloco {
  font-size: 45px;
  color: #fff;
  font-weight: 300;
  text-align: right;
  font-weight: bold;
}

.hdv-info-bloco-active {
  background-color: #44BB52;
}

.hdv-info-bloco-alert {
  background-color: #EED323;
}

.hdv-info-bloco-off {
  background-color: #444444;
}

.hdv-info-bloco-sem-comunicacao {
  background-color: #e75c4f;
}

.rbc-calendar {
  height: 90% !important;
  padding: 10px;
}

.hdv-bloco-height-fix {
  height: 500px !important;
}

.weathercolor {
  background-color: #1f567c !important;
}

.hdv-widget01 {
  padding: 9px;
  background-color: #f2f2f2
}

.hdv-widget01 img {
  width: 100%;
  height: 90px;
}

.hdv-alert-receive {
  position: absolute;
  z-index: 10;
  left: 20px;
  top: 4px;
  background-color: #d94545;
  border-radius: 20px;
  color: #fff;
  font-weight: bold;
  font-size: 11px;
  padding: 3px 5px 0px 5px;
}

.hdv-alert-receive-hidden {
  visibility: hidden;
}

.hdv-menu-item:hover {
  margin: 5px 0px 5px 30px;
}

.hdv-submenu {
  color: #424242;
}

.hdv-submenu:hover {
  text-decoration: none;
  color: #424242;
}

.gmnoprint[role="menubar"] {
  right: 0 !important;
  left: auto !important;
  top: 0 !important;
  height: fit-content !important;
}

button[role="menuitemradio"] {
  height: 30px !important;
  padding: 0 14px !important;
}

.gmnoprint[role="menubar"] label {
  font-size: 12px !important;
}

#map {
  position: absolute;
  /* top: 190px; */
  bottom: 0;
  width: 100%;
  border-radius: 20px;
}

.hdv-chart {
  background-color: #f7f7f7;
  border-radius: 10px;
  padding-bottom: 10px;
  padding-top: 8px;
  margin-top: 5px;
}

.hdv-chart-bloco {
  height: 500px;
  background-color: #fff;
}

.hdv-chart-bloco-reactgridlayout {
  height: 100%;
}

.hdv-chart-refs {
  margin-top: 10px;
  background-color: #f6f6f6;
  margin-bottom: 14px;
  border-radius: 5px;
}

.hdv-chart-refs summary {
  border-radius: 5px;
  background-color: #f6f6f6;
  padding: 10px 4px;
}

.hdv-chart-refs summary:hover {
  filter: brightness(0.94);
}

.hdv-chart-refs section {
  max-height: 200px;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
}

.hdv-ref-item {
  margin: 0 14px;
  padding: 4px 8px;
  min-width: 20%;
  max-width: 20%;
  border-right: 2px solid #ccc;
}

.hdv-ref-item:last-child {
  border-right: none;
}

.hdv-ref-item #ref-line-color {
  width: 16px;
  height: 16px;
  margin: 0 4px;
  border-radius: 20px;
}

.hdv-top-bar-bloco {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-size: 0.9rem;
  padding: 10px;
}

.hdv-zera-padding-left-right-boostrap {
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 10px;
}

.hdv-render-div {
  width: 100%;
  padding: 0px 0px 16px 0px;
  border-radius: 0 !important;
  top: -2px;
  position: relative;
  background-color: white;
  border-radius: 10px !important;
}

.hdv-search-home-field {
  line-height: 39px;
  background-color: #ededed;
  border: 0px;
  width: 340px !important;
  border: 1px none !important;
  border-radius: 50px !important;
  padding-left: 50px !important;
  outline: none;
  color: #676769 !important;
  font-size: 0.85rem;
  font-weight: bold;
}

.hdv-search-home-field::placeholder {
  font-size: 0.85rem;
  color: #979699 !important;
  font-weight: bold;
  font-style: normal;
}

.hdv-search-home-field:hover {
  border: none !important;
}

.hdv-search-home-field:focus {
  border: none !important;
}

.nav-item, .nav-link {
  color: #111 !important;
  padding: 0;
}

.nav-link {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  /* min-width: 52px; */
  margin: 0 3px;
  height: fit-content;
}

.hdv-navbar-item {
  min-height: 46px;
  min-width: 50px;
}

.hdv-navbar-item:hover {
  background-color: #f1f1f1;
  border-radius: 12px;
}

.hdv-btn-forms {
  border: none;
  padding: 7px 26px;
  color: #fff;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  height: 38px;
  line-height: 12px;
  font-size: 0.9rem;
  cursor: pointer;
  font-weight: bold;
}

.hdv-btn-forms:hover {
  opacity: 0.8;
}

.hdv-btn-forms:focus {
  outline: none;
}

.btn-add-margin {
  margin-left: 10px;
}

button[class^="hdv-btn-"] {
  font-family: sans-serif;
  /* font-size: 13px !important; */
  border-radius: 8px !important;
}

.hdv-btn-yellow {
  background-color: #f4be1a;
}

.hdv-btn-green {
  background-color: #44bb52;
}

.hdv-btn-greenlight {
  background-color: #79da9b;
}

.hdv-btn-default {
  background-color: #eeeeee;
  color: #454545;
}

.hdv-btn-red {
  background-color: #e64444;
}

.hdv-btn-red:disabled {
  border: none !important;
}

.hdv-btn-blue {
  background-color: #207fcc;
}

.hdv-btn-orange {
  background-color: orange;
}

.hdv-btn-group {
  margin-top: 20px;
  margin-bottom: 12px;
  display: flex;
}

.hdv-btn-disable {
  background-color: #cacaca !important;
  box-shadow: none !important;
}

.hdv-csv-button {
  background-color: #4da845 !important;
  width: fit-content;
  padding: 10px 32px;
  font-weight: bold;
  border-radius: 8px;
  border: none;
  cursor: pointer;
}

.hdv-aligncenter-checkboxgrid {
  text-align: center;
}

.hdv-form-default {
  font-size: 0.9rem;
}

label.hdv-fixed {
  display: flex !important;
  align-items: center !important;
  margin: 6px 0 !important;
  margin-right: 10px;
  padding-right: 10px;
  color: #555 !important;
}

label.hdv-fixed input[type="radio"], label.hdv-fixed input[type="checkbox"] {
  margin-left: 0;
  margin-right: 8px !important;
}

label.hdv-fixed:has(input[type="radio"]) {
  gap: 4px;
  padding: 2px 8px;
  border-radius: 6px;
  margin: 0 3px !important;
  width: fit-content !important;

  &:hover {
    background-color: rgb(240, 240, 240) !important;
    cursor: pointer;
  }
}

.hdv-form-default label {
  display: block;
  font-size: 13px;
  margin-top: 15px;
}

.hdv-form-default .required {
  font-weight: 600;
}

input[type="text"], input[type="password"], input[type="number"], input[type="time"], input[type="date"], select {
  height: 44px;
  padding: 7px;
  border-radius: 10px;
  border: 1px solid #dfdfdf;
  outline: none;
  width: 100%;

  &:focus {
    border: 1px solid var(--fonte_titulo_abas) !important;
  }
}

.css-1hwfws3 input:focus {
  border: none !important;
}

input[type="date"] {
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  right: 4px;
  top: 7px;
  width: 20px;
  opacity: 0.75;
  height: 20px;
  padding: 3px 6px 3px calc(100% - 40px);
  border-radius: 4px;
}

input[type="text"]:hover, input[type="password"]:hover, input[type="number"]:hover, input[type="time"]:hover, input[type="date"]:hover,
input[type="text"]:focus, input[type="password"]:focus, input[type="number"]:focus, input[type="time"]:focus, input[type="date"]:focus {
  border: 1px solid #bebebe;
}

input[type="checkbox"] {
  border-radius: 5px !important;
  border: none !important;
  background-color: #f2f2f2 !important;
  border: 1px solid #acacac !important;
  height: 20px !important;
  width: 20px !important;
}

input[type="checkbox"]:hover {
  cursor: pointer;
}

input[type="checkbox"]::after {
  margin-left: 2px !important;
  line-height: 22px;
  height: 16px !important;
  border-radius: 20px !important;
  background-color: #777 !important;
}

input[type="checkbox"]:checked {
  background-color: #44bb52 !important;
  border: 1px solid #44bb52 !important;
  position: relative;
}

input[type="checkbox"]:checked::after {
  background: transparent !important;
  content: '✓';
  color: white;
  font-size: 16px;
  font-weight: bold;
}

input[type="time"] {
  width: 100px;
}

.hdv-form-default input[type="text"], input[type="number"]:focus, .hdv-textarea {
  outline: none;
}

.hdv-form-system-password-field {
  height: 42px !important;
  padding: 7px !important;
  border-radius: 8px !important;
  background-color: #fff !important;
  border: 1px solid #dfdfdf !important;
  margin: 0 !important;
  outline: none !important;
  width: 100% !important;
}

input[type="radio"] {
  margin-right: 6px;
  cursor: pointer;
}

.hdv-form-default input[type="radio"] {
  margin-left: 10px;
}

.hdv-form-default select {
  width: 100%;
  border: none;
  padding: 7px;
  background-color: #fff;
  border: 1px solid #dfdfdf;
  border-radius: 10px;
  cursor: pointer;
}

.hdv-textarea {
  min-height: 280px;
  max-height: 380px;
  padding: 7px;
  border-radius: 8px;
  border: 1px solid #d0d0d0;
  outline: none;
  width: 100%;
}

.hdv-subtable-title {
  margin-top: 20px;
  margin-bottom: 10px;
}

.hdv-subtable {
  width: 100%;
  border: 1px solid #ebeced;
  font-size: 0.9rem;
}

.hdv-subtable thead {
  background-color: #f8f9fa;
  text-align: center;
}

.hdv-subtable thead td {
  border: 1px solid #ebeced;
  padding-left: 3px;
  padding: 10px;
}

.hdv-subtable tbody td {
  border: 1px solid #ebeced;
  padding: 5px;
}

.hdv-subtable tbody tr:last-child td {
  border-bottom: none !important;
}

.hdv-subtable-action-td {
  width: 30%;
  min-width: 250px;
  text-align: center;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

.hdv-subtable-action-td button, .clean-action button {
  width: 100px;
  height: 24px !important;
  background-color: transparent;
  font-size: 13px !important;
}

.hdv-subtable-action-td:has(button) {
  border-top: 1px solid #ebeced !important;
}

.hdv-subtable-action-td button:hover, .clean-action button:hover {
  text-decoration: underline;
}

.hdv-subtable-action-td .hdv-btn-red, .clean-action .hdv-btn-red {
  color: rgb(194, 43, 43);
}

.hdv-subtable-action-td .hdv-btn-yellow, .clean-action .hdv-btn-yellow {
  color: rgb(199, 122, 28);
}

.hdv-noshow-item {
  display: none !important;
}

button:disabled, button[disabled], .btn-form-wrapper button {
  background-color: #cacaca !important;
  box-shadow: none !important;
  font-weight: bold;
  color: white;
}

.hdv-form-error {
  border: 2px solid red !important;
}

.display-none {
  display: none;
}

.scroll-parent {
  height: 100%;
  padding-top: 220px;
  position: relative;
  z-index: 20;
  top: -220px;
  min-width: 250px !important;
}

#scroll-menu {
  overflow: auto;
  position: relative;
  height: calc(100%);
  padding-bottom: 40px;
  min-width: 250px;
}

#scroll-menu::-webkit-scrollbar-track {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.4) !important;
}

#scroll-menu::-webkit-scrollbar {
  width: 5px;
  background-color: #37424a00;
}

#scroll-menu::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 4px;
}

.hdv-header-filters {
  border-radius: 5px;
}

.hdv-header-filters label {
  display: block;
  font-size: 13px;
}

.clean-btn {
  padding: 1px 4px;
  font-weight: bold;
  background: transparent;
  text-decoration: underline;
}

.hdv-header-filters select {
  width: 100%;
  padding: 7px;
  border-radius: 5px;
  border: 2px solid #d0d0d0;
  height: 42px;
  outline: none;
}

.hdv-header-filters input[type="text"] {
  padding: 7px;
  border-radius: 5px;
  /* border: 2px solid #d0d0d0; */
  outline: none;
}

.hdv-header-filters-button {
  border: 2px solid #007bff;
  background-color: #fff;
  color: #77aeee;
  padding: 7px;
  border-radius: 5px;
  margin-left: 4%;
  width: 20%;
  outline: none;
  height: 42px
}

.hdv-generate-report {
  float: right;
  background-color: #007bff !important;
  color: #fff !important;
  margin-top: 15px;
  border: none;
  border-radius: 10px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  cursor: pointer;
}

.hdv-export-csv {
  background-color: #4da845 !important;
  width: 120px;
  height: 41px;
  margin: 15px 0;
  border-radius: 8px;
  border: none;
  cursor: pointer;
}

.hdv-generate-pdf {
  float: left;
  background-color: skyblue !important;
  color: #fff !important;
  margin-top: 15px;
  border-radius: 10px;
  border: none;
  margin-left: 0px !important;
  margin-right: 0px !important;
  cursor: pointer;
  width: 125px;
}

.hdv-block-pdf-border {
  border: 2px solid skyblue !important;
  background-color: skyblue !important;
}

.hdv-block-button {
  /* border: 2px solid #acacac !important; */
  background-color: #acacac !important;
}

.hdv-report-loading {
  position: relative;
  float: right !important;
  top: -20px;
  height: 0 !important;
  right: 10px !important;
}

.hdv-report-loading-left {
  position: relative;
  float: left;
  top: 10px;
  left: 10px;
}

.hdv-title-create-profile {
  font-size: 15px;
  margin-top: 10px;
  background-color: #dddddd;
  color: #444;
  padding: 5px 6px 5px 6px;
  font-weight: bold;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.custom-summary {
  color: #444;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
}

.custom-summary > div {
  border-radius: 5px;
}

.custom-summary span {
  font-size: 14px;
}

.hdv-required-field {
  color: #ff0000b8;
  font-size: 12px !important;
  font-weight: bold;
  position: relative;
  left: 3px;
}

.custom-summary summary {
  list-style: none;
  padding: 10px 10px !important;
  border-radius: 5px 5px 0 0 !important;
  background-color: #eeeeee;
  position: relative;
  color: #979797;
  font-weight: bold;
}

.custom-summary summary::after {
  content: "+";
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 16px;
  font-weight: bold;
  opacity: 0.9;
}

.custom-summary[open] summary::after {
  content: "—";
  position: absolute;
  right: 10px;
}

.custom-summary summary.no-arrow::after {
  content: "";
}

.custom-summary section {
  padding: 14px 8px;
}

.custom-summary button {
  padding: 6px 8px !important;
}

.custom-summary .button-details {
  padding: 7px 26px !important;
}

.hdv-permission-block {
  padding: 2px 6px;
}

.hdv-permission-description {
  margin-left: 12px;
  font-size: 12px;
}

.hdv-block-group {
  border-bottom: 1px solid #d0d0d0;
}

.hdv-open-close-block-group {
  float: right;
  color: #000;
  font-size: 16px;
  cursor: pointer;
}

.hdv-dias-semana-automacao {
  display: flex;
  flex-direction: column;
}

.hdv-dias-semana-automacao label {
  display: flex;
  color: #111 !important;
  font-size: 13px !important;
  margin: 0;
  padding: 3px 0;
}

.hdv-dias-semana-automacao input[type="checkbox"] {
  margin-right: 8px;
}

.select-supervisory {
  min-width: 300px;
  width: 50%;
  max-width: 450px;
}

.select-supervisory .css-1600srv {
  height: 38px !important;
}

.select-supervisory .css-1wy0on6 {
  margin-top: -4px !important;
}

.css-10nd86i {
  font-size: 12px;
  height: 40px;
}

.css-vj8t7z .css-1hwfws3 {
  height: 40px;
}

.css-2o5izw .css-1hwfws3 {
  height: 40px !important;
}

.css-xp4uvy {
  top: 66% !important;
}

.css-1492t68 {
  display: none;
}

.css-162g8z5 {
  font-size: 12px;
  height: 40px;
}

.css-1wy0on6 {
  height: 44px;
}

.rdrDateDisplayWrapper {
  background-color: #eee !important;
}

.hdv-datepicker {
  position: absolute !important;
  width: fit-content;
  right: 16px;
  background-color: #fff;
  z-index: 9999 !important;
  border-radius: 5px !important;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
  display: flex;
  padding: 0 0 8px 0;
  flex-direction: column;
  justify-content: center;
}

.hdv-datepicker .rdrDateRangePickerWrapper {
  width: 100% !important;
}

.hdv-datepicker .rdrDateRangePickerWrapper {
  border-radius: 5px !important;
  overflow: hidden;
}

.rdrMonth {
  padding: 4px 8px !important;
}

.rdrMonthAndYearWrapper {
  height: 40px !important;
}

.rdrMonthAndYearWrapper select {
  padding: 4px 30px !important;
  height: 28px;
}

.hdv-datepicker .rdrMonthAndYearWrapper button {
  background-color: #ddd;
}

.hdv-datepicker-display-none {
  display: none;
}

button, input, optgroup, select, textarea {
  font-size: 12px !important;
}

textarea {
  border: 1px solid #c8c8c8;
}

.hdv-force-hight {
  height: 42px !important;
  background-color: transparent !important;
  border-radius: 6px;
}

.hdv-force-hight::placeholder {
  color: #555 !important;
}

.hdv-top15 {
  margin-top: 15px !important;
}

.hdv-resumo-titulo {
  font-size: 13px;
}

.hdv-resumo-valor {
  font-size: 13px;
  font-weight: bold;
}

.hdv-logomarca-preview {
  width: 200px;
  border-radius: 8px;
}

.hdv-file-picker {
  padding-bottom: 10px;
}

.hdv-file-picker input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  margin-top: 23px;
  width: 200px;
}

.hdv-file-picker input+label {
  background-color: #eee;
  padding: 11px;
  border-radius: 6px;
  display: inline-block;
  border: 1px solid #eee;
  text-align: center;
  color: #555;
  width: 200px;
}

.hdv-file-picker input:hover+label,
.hdv-file-picker input+label:hover {
  cursor: pointer;
  border: 1px solid #cacaca;
}

div[class^="input-group-"] .btn-info {
  background-color: #eee !important;
  color: #333;
  font-weight: bold;
  border: 1px solid #eee;
  box-shadow: none !important;
}

.input-group .input-group-prepend .btn-info {
  border-right: 1px solid #b4b4b4 !important
}

.input-group .input-group-append .btn-primary, .input-group .input-group-append .btn-danger {
  border-radius: 0 9px 9px 0 !important;
}

.input-group .input-group-append .btn-primary, .input-group .input-group-append .btn-danger {
  border: none !important;
  box-shadow: none !important;
  border-radius: 0 6px 6px 0;
  width: 130px;
}

div[class^="input-group-"] .btn-info:active, div[class^="input-group-"] .btn-info:hover {
  color: #000 !important;
  border: 1px solid #b4b4b4 !important;
}

.hdv-fieldset {
  padding: 10px 10px 30px 10px;
  margin-top: 20px;
  border-radius: 5px;
  background-color: #f6f6f6;
}

.hdv-fieldset .hdv-legend {
  background-color: transparent;
}

legend {
  width: fit-content;
}

.hdv-legend {
  display: block;
  width: fit-content;
  max-width: 100%;
  padding: 0px 5px 0px 5px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: inherit;
  color: inherit;
  white-space: normal;
  background-color: #fff;
  font-weight: bold;
}

.hdv-automacao-order-button {
  background-color: #eee;
  padding: 4px 8px;
  border-radius: 4px;
  min-width: 80px;
  margin-right: 4px;
  border: 1px solid #9b9b9b;
}

.hdv-automacao-order-button:hover {
  filter: brightness(0.92);
}

.hdv-automacao-add-new-button {
  margin-top: 15px;
  padding: 0px 10px !important;
}

.hdv-automacao-delete-button {
  padding: 8px 10px 7px 10px;
  display: flex;
  align-items: center;
}

.hdv-automacao-delete-button:hover {
  filter: brightness(0.9);
  cursor: pointer;
}

.hdv-icon-position-homebox {
  position: absolute;
  bottom: 0px;
  font-size: 70px;
  opacity: 0.1;
}

.hdv-icon-text-homebox {
  font-weight: bold;
}

.hdv-mapform {
  position: relative;
  height: 400px;
  margin-top: 25px;
}

.compact-picker {
  height: 100px !important;
}

.hdv-menu-comandos {
  text-align: left;
  background: #7d7d7d;
  border-radius: 0px 4px 4px 4px;
  position: absolute;
}

.afr-header-options summary.afr-popover-button {
  background-color: #e9e9e9;
}

.afr-header-options summary.afr-popover-button i {
  color: #444 !important;
}

.hdv-popup-comando {
  list-style: none;
  padding: 0px;
  margin: 5px 5px 5px 0px;
}

.hdv-mapform-status {
  position: relative;
  height: 180px;
  margin-top: 8px;
}

.hdv-search-results {
  background-color: #fff;
  border-radius: 14px;
  padding: 16px 0;
  margin: 0;
  width: 330px;
  top: 88px;
  position: absolute;
  z-index: 10;
  min-height: 120px;
  min-width: 260px !important;
  border: 3px solid rgb(236, 236, 236);
  box-shadow: rgb(236, 236, 236) 5px 5px 0px -1px !important;
  max-height: 300px;
  overflow: auto;
}

.hdv-search-results ul {
  padding: 4px 0;
}

.hdv-search-results span {
  opacity: 0.7;
  font-weight: bold;
  padding: 8px 22px;
}

.hdv-search-results li {
  list-style: none;
  color: #000;
  background-color: #fff;
  cursor: pointer;
  padding: 6px 22px;
  color: #1d7bd8;
}

.hdv-search-results li:hover {
  filter: brightness(0.93);
}

.hdv-default-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px !important;
  border-bottom: 2px solid #e6e6e6;
  padding-bottom: 10px;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.hdv-default-header h4 {
  margin: 0;
}

/*CUSTOMIZACAO REACT TABS*/

.react-tabs__tab:active {
  border: 1px solid var(--fonte_titulo_abas) !important;
  border-bottom: none !important;
}

.tabs-style {
  margin-top: 4px;
  border-bottom: 0px !important;
}

.react-tabs__tab--selected {
  background-color: var(--background-menu) !important;
}

.react-tabs__tab--disabled {
  background-color: #8D8B8B !important;
  color: #BAB7B3 !important;
  cursor: not-allowed !important;
}

.rdrDefinedRangesWrapper {
  display: none;
}

span[id^="validacao-"] {
  width: 100%;
  background-color: tomato !important;
  color: #fff;
  display: block;
  border-radius: 5px;
  padding-left: 5px;
  margin-top: 3px;
}

.operacao-invalida {
  padding: 12px 0px;
  color: rgb(210, 88, 67);
  font-weight: bold;
  font-size: 14px;
  text-align: center;
}

.operacao-valida {
  padding: 12px 0px;
  font-size: 14px;
  color: rgb(69, 187, 89);
  font-weight: bold;
  text-align: center;
}

.tabela-eventos {
  overflow-y: auto;
  max-height: 320px;
  border: 1px solid #dadada;
}

.tabela-eventos td {
  padding: 3px 8px;
}

.input-atraso {
  max-width: 140px !important;
  height: 30px !important;
  border: 1px solid #b6b6b6 !important;
}

i.fa span {
  display: none;
}

#font {
  margin: 0;
  color: #222 !important;
}

i.fa:hover span {
  display: block;
  position: absolute;
  width: 220px;
  padding: 15px 10px;
  border-radius: 3px;
  font-size: 14px;
  font-family: sans-serif;
  background-color: #fff;
  box-shadow: 0 0 9px #a1a1a1;
  animation: fade-in ease-in-out .26s;
}

.exportar-csv {
  background: #4da845;
  width: fit-content;
  height: 40px;
  margin: 15px 0;
  float: right;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.exportar-csv p {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin: 0;
  color: #fff;
  padding: 8px 10px;
}

.modal {
  padding-top: 30px !important;
  padding-bottom: 40px !important;
}

.modal-content {
  box-shadow: rgb(236, 236, 236) 5px 5px 0px -1px;
  border: 5px solid rgb(236, 236, 236);
  border-radius: 15px;
  padding: 15px;
}

.modal-header {
  padding: 7px 4px !important;
  display: flex !important;
  margin: 0 12px !important;
  align-items: center;
  height: 40px !important;
}

.modal-header button.close {
  font-size: 1.8rem !important;
  padding: 10px 16px;
  outline: 0;
}

.modal-footer {
  border-top: none;
  padding: 0px !important;
}

.modal-title {
  margin: 10px 0px !important;
  color: var(--fonte_titulo_abas) !important;
  font-weight: bold !important;
}

.hdv-resumo-relatorio {
  font-size: 18px;
  font-weight: bold;
  padding: 0 6px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.hdv-resumo-content {
  display: flex;
  flex-wrap: wrap;
}

.hdv-resumo-content div {
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
  padding: 32px 8px 8px 8px;
  border-radius: 10px;
  margin: 7px 10px 8px 0;
  font-size: 12px;
  width: 25%;
}

.hdv-resumo-content div strong {
  font-size: 20px;
}

table.hdv-table-analitico {
  width: 100%;
}

.hdv-table-analitico thead {
  background-color: #dbdbdb;
}

.hdv-table-analitico thead th {
  padding: 12px 6px;
}

.hdv-table-analitico thead th:first-child {
  border-radius: 7px 0 0 7px;
}

.hdv-table-analitico thead th:last-child {
  border-radius: 0 7px 7px 0;
}

.hdv-table-analitico thead th {
  position: relative;
}

.hdv-table-analitico thead th::after {
  position: absolute;
  right: 0px;
  top: 10px;
  content: '';
  width: 2px;
  background-color: #aaaaaa;
  height: 25px;
  border-radius: 2px;
}

.hdv-table-analitico thead th:last-child::after {
  content: none;
}

.hdv-table-analitico tbody tr:nth-child(odd) {
  background-color: #eeeeee;
}

.hdv-table-analitico tbody td {
  padding: 0 5px !important;
}

.hdv-table-analitico tbody td:first-child {
  border-radius: 7px 0 0 7px !important;
}

.hdv-table-analitico tbody td:last-child {
  border-radius: 0 7px 7px 0 !important;
}

.hdv-table-analitico thead {
  font-size: 12px;
}

.hdv-table-analitico tbody td {
  font-size: 12px;
  position: relative;
}

.hdv-table-analitico tbody td::after {
  position: absolute;
  right: 0px;
  top: 10px;
  content: '';
  width: 2px;
  background-color: silver;
  height: 25px;
  border-radius: 2px;
}

.hdv-table-analitico tbody td:last-child::after {
  content: none;
}

.hdv-table-analitico .hdv-cell {
  padding: 0 0 !important;
  margin: 0 0 !important;
}

.hdv-table-analitico .hdv-cell p {
  margin: 0 8px;
  padding: 2px 8px;
}

.hdv-table-analitico .hdv-cell-border {
  border-bottom: 1px solid #cacacace;
}

.hdv-table-analitico .hdv-center {
  padding: 13px 4px !important;
}

.hdv-badge {
  padding: 0px 7px;
  float: right;
  margin: 0 6px;
  border-radius: 50%;
}

.badge {
  font-size: 10px;
  padding: 3px 6px;
  line-height: 9px;
}

.hdv-observacao-modal,
.modal-large {
  min-width: 70% !important;
}

.modal-md {
  min-width: 50% !important;
}

.marker-filter:hover {
  cursor: pointer;
  filter: brightness(0.93);
}

.marker-filter.disabled {
  filter: brightness(0.7);
}

.aviso-backdrop {
  left: 0;
  top: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
  width: 100%;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.aviso-feedback {
  background-color: #ffffff;
  box-shadow: rgb(236, 236, 236) 5px 5px 0px -1px;
  border: 5px solid rgb(236, 236, 236);
  border-radius: 15px;
  padding: 15px;
  width: 500px;
  font-size: 14px;
}

.aviso-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}

.aviso-buttons button {
  padding: 6px 20px
}

.campo-customizado {
  margin-top: 15px;
  font-size: 13px;

  ul {
    border-radius: 6px;
  }
}

.campo-customizado details {
  margin: 4px 0;
}

.campo-customizado details summary {
  border-radius: 5px;
  padding: 7px 8px;
  background-color: #f2f2f2;
  user-select: none;
}

.campo-customizado details summary:hover {
  filter: brightness(0.93);
}

.campo-customizado details div {
  padding: 0 6px;
  font-size: 14px;
  opacity: 0.94;
  user-select: none;
}

.campo-customizado p {
  margin: 4px 0 !important;
  border-radius: 4px;
  padding: 5px 8px;
  width: 100%;
  background-color: #eee;
}

.campo-customizado #variavel:hover {
  color: dodgerblue;
  cursor: pointer;
}

.hdv-param-number::-webkit-outer-spin-button,
.hdv-param-number::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

.hdv-param-buttons {
  margin-left: 6px;
  overflow: hidden;
}

.hdv-param-buttons button {
  background: #e4e4e4;
  padding: 1px 6px;
  border: none;
  font-size: 14px !important;
}

.hdv-param-buttons button:hover {
  filter: brightness(0.93);
}

.hdv-relatorio-btn-menu {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 0px;
  top: 52px;
  padding: 0;
}

.hdv-relatorio-btn-menu #loading {
  position: relative;
  top: -14px;
}

.hdv-relatorio-details {
  border: 0.5mm solid #d9d9d9;
  border-radius: 4px;
}

.hdv-relatorio-details input[type="color"] {
  background-color: #eee !important;
  border-radius: 6px;
  border: none;
  position: relative;
  height: 40px;
  width: 80px;
}

.hdv-relatorio-details label {
  display: flex;
  flex-direction: column;
  margin: 0;
}

.hdv-chart-close,
.hdv-chart-edit {
  border: none;
  border-radius: 4px;
  padding: 0;
  margin: 0 5px;
  height: 28px;
  width: 28px;
  color: #fff;
  cursor: pointer;
}

.hdv-chart-close:hover,
.hdv-chart-edit:hover {
  filter: brightness(0.92);
}

.hdv-chart-options {
  display: flex;
  align-items: center
}

.hdv-chart-options button {
  padding: 10px 10px !important;
  margin: 0;
  margin-left: 8px;
}

.hdv-relatorio-chart {
  overflow: hidden;
}

.hdv-relatorio-chart:first-child {
  border-top: 1px solid #d5d5d5;
}

.hdv-relatorio-details summary::-webkit-details-marker,
.hdv-relatorio-chart summary::-webkit-details-marker {
  display: none;
}

.hdv-relatorio-chart details:hover {
  filter: brightness(0.8);
}

.hdv-relatorio-chart summary {
  background-color: #fff;
  list-style: none;
  padding: 5px 8px;
}

.hdv-relatorio-chart #content {
  padding: 6px 14px;
}

.hdv-relatorio-chart summary:hover {
  filter: brightness(0.94);
}

.hdv-relatorio-details button {
  padding: 2px;
  border-radius: 50%;
  background-color: #777;
  justify-content: center;
  display: flex;
  align-items: center;
}

.hdv-relatorio-details summary button i {
  font-size: 20px !important;
}

#title-chart-config {
  font-size: 16px !important;
  width: 300px;
  padding: 4px 10px;
}

.hdv-resumo-list {
  display: flex;
  margin-top: 12px;
  padding: 2px 0;
  overflow-x: auto;
  position: relative;
  padding-top: 18px;
}

.hdv-resumo-list::before {
  position: absolute;
  top: -3px;
  opacity: 0.88;
  z-index: 1000;
  content: "Resumos:";
}

.hdv-resumo-list .hdv-resumo {
  height: 110px;
  width: 240px;
  word-break: break-all;
  min-width: 160px;
  margin: 4px 0;
  margin-right: 10px;
  background-color: #f6f6f6;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-size: 13px;
  opacity: 0.8;
}

.hdv-resumo-list .hdv-resumo #content {
  display: flex;
  flex-direction: column;
}

.hdv-resumo-list .hdv-resumo #content strong {
  font-size: 24px;
}

.hdv-resumo-list .hdv-resumo i {
  font-size: 40px;
  margin: 0 6px;
}

.hdv-resumo-list .hdv-resumo i.hide-icon {
  font-size: 12px;
  opacity: 0;
}

.hdv-resumo-list .hdv-resumo small {
  font-size: 12px;
  display: block;
}

#hdv-content {
  padding: 10px 12px;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  justify-content: stretch;
}

#hdv-content #ref-item section {
  display: none;
}

.sideccr-table td[hdv-title]:hover::before {
  content: attr(hdv-title);
  position: absolute;
  background-color: #fff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.6);
  padding: 3px 8px;
  border-radius: 4px;
  left: 0px;
}

.rele-bullet {
  width: 15px;
  height: 15px;
  display: inline-block;
  border-radius: 15px;
  margin-left: 2px;
}

.rele-bullet-on {
  background-color: rgb(17, 189, 100);
}

.rele-bullet-off {
  background-color: rgb(100, 100, 100);
}

.rt-th>input {
  height: 42px !important;
}

.hdv-multi-report-item {
  border: 1px solid #d0d0d0;
  border-radius: 4px;
  padding: 4px 8px;
  margin-bottom: 4px;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hdv-multi-report-item p {
  margin: 0;
}

.hdv-multi-report-item span {
  opacity: 0.85;
}

.hdv-multi-report-item button {
  border: none;
  border-radius: 50%;
  padding: 4px;
  margin: 0 2px;
}

#multi-item-list {
  padding: 0;
  border: 1px #d2d2d2 solid;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  height: 260px;
  overflow-y: scroll;
}

#multi-item-list label {
  padding: 2px 6px;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  user-select: none;
}

#multi-item-list label:hover {
  filter: brightness(0.92);
}

#multi-item-selected {
  min-height: 150px;
  max-height: 400px;
  overflow-y: auto;
}

.hdv-multi-report-table progress {
  min-width: 85%;
  margin-right: 6px;
}

#hdv-multi-report-print {
  padding-bottom: 25px !important;
}

#hdv-multi-report-print table progress {
  width: 82%;
}

#multi-item-list button {
  border: none;
  padding: 8px;
  background-color: #dedede;
  margin: 5px;
  border-radius: 4px;
}

#multi-item-list label button {
  padding: 3px;
  margin: 2px;
}

#multi-item-list button:hover {
  background-color: #0062cc;
  color: white;
}

#multi-item-list .multi-item-add {
  padding: 0 26px !important;
  color: #333;
  margin: 0 !important;
  user-select: none;
  font-size: 20px !important;
  font-weight: bold;
}

span.multi-item-selected {
  color: #0062cc;
  font-weight: bold;
}

.usuario {
  border: none !important;
}

.hdv-multi-report-dispositivos#perfil-usuario {
  position: -webkit-sticky;
  position: sticky;
  top: 40px;
}

.hdv-multi-report-dispositivos#perfil-usuario section {
  max-height: 480px;
  min-height: 150px;
  overflow-y: auto;
}

@keyframes warning_animation {
  from {
    box-shadow: none;
  }

  to {
    box-shadow: 0 0 0 2px #0062cc;
  }
}

.dropdown-menu {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.28);
}

.dropdown-item {
  padding: 4px 5px !important;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dropdown-item i {
  font-size: 23px;
  margin-right: 8px;
}

.dropdown-item:active {
  background-color: inherit !important;
  color: inherit !important;
  filter: brightness(0.9);
}

.dropdown-divider {
  margin: 2px 8px !important;
}

#campo-guide-line {
  position: relative;
}

#campo-guide-line::after {
  position: absolute;
  top: 40px;
  content: '';
  width: 16px;
  border-bottom: 2px solid #dbdbdb;
}

.inline-menu-options-page {
  display: inline-flex;
  column-gap: 10px;
}

.inline-menu-options-page summary {
  position: relative;
}

.inline-menu-options-page summary:hover span {
  display: block;
}

.inline-menu-options-page summary.popover-active {
  background-color: rgb(250, 151, 39);
}

.inline-menu-options-page summary.popover-active i {
  color: #fff !important;
}

.inline-menu-options-page summary span {
  display: none;
  position: absolute;
  top: 42px;
  left: -24px;
  text-align: center;
  padding: 1px 0;
  border-radius: 4px;
  width: 90px !important;
  background-color: #444;
}

.screen-status-options {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  /* background-color: #e6e6e6; */
  border-radius: 5px;
}

.screen-status-options label {
  padding: 4px 20px;
  margin-right: 30px;
}

.screen-status-options input[type="checkbox"] {
  margin-right: 8px;
}

.status-date-picker .date-range-selection {
  position: absolute;
  right: -4px;
  top: -4px;
  background-color: #e9e9e9;
  z-index: 1000;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 25px;
  border-radius: 8px;
  color: #444;
  padding: 7px 8px;
}

.status-date-picker .hdv-datepicker {
  right: -2px;
  top: 38px;
  z-index: 100;
}

.hdv-datepicker .btn-success {
  margin-top: 8px !important;
}

.status-date-picker .btn-primary {
  margin-top: 8px !important;
}

.status-date-picker .date-range-selection:hover, #map-details:hover {
  filter: brightness(0.92);
  cursor: pointer;
}

.screen-status-alert {
  background-color: #ffe600
}

.screen-status-automation {
  background-color: #1ad314
}

.screen-status-automation>i {
  color: #fff !important;
}

.screen-status-alert>i {
  color: #000 !important;
}

.status-box-field {
  width: 270px;
  padding: 12px;
  height: 90px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  min-width: 240px;
  cursor: pointer;
  user-select: none;
  position: relative;
  border: 1.5px solid rgba(0, 0, 0, 0.100);
  margin: 5px 0px 5px 0px
}

.tremor {
  animation: tremor 0.6s infinite ease-in-out;
}

.status-box-field .status-box-bg {
  left: 0;
  top: 0;
  border-radius: 15px;
  z-index: -1;
  position: absolute;
  width: 270px;
  padding: 12px;
  height: 90px;
  background-color: #eeecec;
}

.status-box-field.active .status-box-bg {
  background-color: var(--background-menu);
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.096)
}

.status-box-field.active:hover .status-box-bg {
  filter: brightness(1.2);
}

.status-box-field #icon-status {
  color: rgb(203 203 203 / 55%);
}

.status-box-field.active * {
  color: var(--fonte_menu_lateral) !important;
}

.status-box-field:hover {
  filter: brightness(0.95);
}

.status-box-field:hover button {
  display: block;
}

.status-box-field button {
  display: none;
  right: 4px;
  top: 4px;
  padding: 6px 7px;
  position: absolute;
  background-color: transparent;
  width: fit-content;
  border-radius: 32px;
  opacity: 0.9;
}

.status-box-field button:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.status-box-field div {
  font-size: 14px;
  color: #797979;
}

.status-box-field strong {
  font-weight: bold;
  font-size: 18px;
  color: #6f6f6f;
  margin-top: 8px;
}

.fields-slide {
  display: inline-flex;
  column-gap: 22px;
  transform: translateX(0px);
  transition: transform 0.5s;
  min-height: 90px;
}

.fields-slide .comandos-flex {
  margin-bottom: 0px;
}

.fields-list {
  width: 100%;
  position: relative;
  margin-bottom: 18px;
  margin-top: 8px;
  overflow: hidden;
  display: inline-flex;
  border-radius: 12px;
}

.status-chart-map-box {
  display: inline-flex;
  width: 100%;
  margin-bottom: 20px;
}

.status-chart-map-box>div:first-child {
  background-color: #f2f2f2;
  border-radius: 15px;
  height: 380px;
  padding: 10px;
}

.status-chart-map-box>div:first-child>.echarts-for-react {
  padding: 4px;
}

.status-chart-map-box>div:last-child {
  background-color: transparent;
  border-radius: 15px;
  height: 300px;
}

.fields-box-move {
  height: 90px;
  z-index: 2;
  position: absolute;
  display: flex;
  padding: 0 2px;
  justify-content: center;
  align-items: center;
  background-color: var(--fonte_titulo_abas);
  filter: brightness(1.3);
  color: #fff;
  cursor: pointer;
}

.fields-box-move-right {
  position: absolute;
  height: 94%;
  right: -3px;
  border-radius: 0 13px 13px 0;
  margin-top: 3px;
}

.fields-box-move-left {
  position: absolute;
  height: 94%;
  left: -3px;
  border-radius: 13px 0 0 13px;
  margin-top: 3px;
}

.fields-box-move>i {
  transition: transform 0.5s;
}

.fields-box-move:hover>i {
  transform: translateY(-3px);
}

.map-status {
  position: relative;

  #map-details {
    position: absolute;
    z-index: 9999;
    right: 6px;
    margin-top: 6px;
    border-radius: 8px;
    padding: 6px 8px;
    color: #111;
    background-color: #ffffff;
    opacity: 1 !important;
    box-shadow: 0 0px 4px rgba(0, 0, 0, 0.45);
    top: 4px !important;
    width: 38px;
  }

  #map {
    right: -1px;
    width: calc(100% - 18px);
  }

  #map .leaflet-control-attribution {
    display: none;
  }
}


.map-status>#map .gm-style .gm-bundled-control, .map-status>#map a, .map-status>#map .gm-style-cc {
  display: none;
}

.react-tabs__tab-list {
  overflow-x: auto;
  overflow-y: hidden;
  border-bottom: 2px solid var(--fonte_titulo_abas);
  margin: 0 !important;
  white-space: nowrap;
}

.react-tabs__tab {
  padding: 12px 20px !important;
  border-top-left-radius: 10px !important;
  font-weight: bold;
  font-size: 0.9rem;
  user-select: none;
  box-shadow: none !important;
  border-top-right-radius: 10px !important;
  color: #979797;
  font-size: 13px;
  margin-bottom: 1px;
  background-color: #dedede;
  border-radius: 5px 5px 0px 0px;
  margin-left: 2px;
  border-bottom: 1px none;
}

.react-tabs__tab-list>.react-tabs__tab:first-child {
  margin-left: 0px;
}

.hdv-form-default label {
  color: #979797;
}

.react-tabs__tab-panel--selected {
  padding: 10px 0px 0px 0px !important;
}

.css-ujflth {
  background-color: #fff !important;
  height: 44px !important;
}

.equipamento-info-parametrizacao {
  padding: 50px 10px;
  display: inline-flex;
  justify-content: center;
  width: 100%;
  font-size: 15px;
  color: #979797;
}

.box-list-novo-parametrizacao {
  display: flex;
  column-gap: 10px;
  flex-wrap: wrap;
  row-gap: 10px;
}

.device-export-options {
  position: absolute;
  top: -52px;
  right: 0;
  display: flex;
  background-color: #ececec;
  border-radius: 6px;
  padding: 4px 10px;
  align-items: center;
}

.device-export-options i {
  margin-right: 5px;
}

.device-export-options-menu {
  top: -18px !important;
  width: fit-content;
}

.parametrizacao-btn-novo #warning {
  display: none;
}

.parametrizacao-btn-novo.disabled {
  background-color: #b7b7b7 !important;
  border-radius: 10px;
  position: relative;

  & * {
    background-color: transparent !important;
  }

  #warning {
    left: 0;
    position: absolute;
    width: 360px;
    color: rgb(161, 26, 26);
    background: #f5f5f5 !important;
    padding: 3px 4px;
    user-select: none;
    left: -80px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.34);
    z-index: 99999;
    bottom: 30px;
    border-radius: 4px;
    text-align: center;
  }

  &:hover #warning {
    display: block;
  }
}

.parametrizacao-btn-novo>a {
  width: 200px;
  height: 200px;
  font-size: 130px;
  background-color: #74c17d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
}

.parametrizacao-btn-novo>a:hover {
  background-color: #77cc81;
  text-decoration: none;
}

.parametrizacao-btn-novo>a>span {
  font-size: 13px;
  font-weight: bold;
}

.parametrizacao-btn-editar {
  width: 200px;
  height: 200px;
  background-color: #eeeeee;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #525252;
  border-radius: 10px;
}

.comandos-flex {
  min-width: 300px;
  max-width: 100%;
  width: 100%;
  min-height: 200px;
  /*margin-bottom: 35px;*/
}

.comandos-flex label {
  font-size: 13px !important;
}

.comandos-flex .comandos-parametros {
  padding: 8px 12px !important;
  width: 100%;
}

.parametrizacao-btn-editar .dropdown, .comandos-btn-editar .dropdown {
  background-color: #d8d8d8;
  border-radius: 0 0 8px 12px;
  width: 100%;
}

.parametrizacao-btn-editar #chart-type {
  font-size: 23px;
}

.parametrizacao-btn-editar small {
  font-size: 13px;
}

.parametrizacao-btn-editar span {
  display: inline-block;
}

.parametrizacao-btn-editar>div {
  font-size: 14px;
  text-align: center;
}

.parametrizacao-btn-editar .row span {
  font-size: 13px;
}

.btns-parametrizacao-novo {
  text-align: center;
  display: flex;
  justify-content: center;
  background-color: #d8d8d8;
  font-weight: bold;
  color: #525252;
  border-radius: 0 0 8px 8px;
  width: 100%;
}

.box-list-novo-comandos {
  display: flex;
  column-gap: 10px;
  flex-wrap: wrap;
  row-gap: 10px;
}

.comandos-btn-novo>a {
  width: 200px;
  height: 200px;
  font-size: 130px;
  background-color: #74c17d;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  border-radius: 10px;
}

.comandos-btn-novo>a:hover {
  background-color: #77cc81;
  text-decoration: none;
}

.comandos-btn-novo>a>span {
  font-size: 13px;
  font-weight: bold;
}

.comandos-btn-editar>div:first-child {
  font-size: 25px;
  font-weight: bold;
}

.comandos-btn-editar {
  width: 200px;
  max-height: 200px;
  height: 200px;
  background-color: #eeeeee;
  color: #525252;
  border-radius: 10px;
  position: relative;
  padding: 10px 0 0px 0px;
}

.comandos-btn-editar .dropdown-menu {
  padding: 6px 0;
}

.comandos-btn-editar #card-dropdown-toggle {
  width: 0px !important;
  background-color: #d2d2d2 !important;
  margin: 0;
  padding: 0;
}

.comandos-btn-editar .dropdown-menu button, .comandos-btn-editar #card-dropdown-toggle {
  width: 99% !important;
  padding: 4px 12px !important;
  color: #363636;
  text-shadow: none;
}

.comandos-btn-editar .comandos-parametros {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: calc(100% - 50px);
  overflow-y: scroll;
  padding: 8px 0;
  padding-bottom: 60px !important;
}

#btn-testar {
  background-color: #1472d6;
  padding: 5px 8px;
  color: #fff;
  font-weight: bold;
  border-radius: 6px;
}

#btn-testar:hover {
  cursor: pointer;
  opacity: 0.8;
}

.comandos-parametros label {
  font-size: 12px;
  font-weight: normal;
  display: flex;
  flex-direction: column;
  color: #666;
  text-align: left;
  margin-top: 3px;
  width: 80%;
  /* margin-left: 8%; */
}

.comandos-btn-editar .comandos-parametros select {
  background-color: white;
  border: none;
}

.comandos-btn-editar .comandos-parametros::-webkit-scrollbar {
  width: 5px;
}

.comandos-btn-editar .comandos-parametros:hover::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 4px;
}

.comandos-btn-editar div {
  margin: 0 auto;
  text-align: center;
  width: 90%;
}

.comandos-btn-editar select {
  width: 100% !important;
  height: 42px;
}

.comandos-btn-editar input {
  width: 100%;
  border-radius: 8px;
  border-style: none;
  height: 40px;
  padding: 10px;
}

.comandos-btn-editar div span {
  font-size: 13px;
  color: #555;
  text-align: center;
}

.btns-comandos-novo {
  border-radius: 0 0 8px 8px;
  z-index: 1000;
  margin: 0 !important;
  display: flex;
  position: relative;
  background-color: #d8d8d8;
  padding: 6px;
  text-align: center !important;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 31.5px;
}

.btns-comandos-novo span {
  color: #525252 !important;
  font-weight: bold;
}

.comandos-parametros-send {
  width: 100%;
  background: var(--background-menu);
  color: var(--fonte_menu_lateral);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 9px 6px;
  line-height: 9px;
  position: absolute;
  overflow: hidden;
  height: 31.5px;
  border: 3px solid rgba(0, 0, 0, 0.116);
  bottom: 0;
  left: 0;
}

.comandos-parametros-send .loading-state {
  height: 40px;
  background-color: rgba(103, 245, 103, 0.336);
  pointer-events: none;
  transition: width .1s;
  width: 0;
  position: absolute;
  left: 0;
  top: 0;
}

.comandos-parametros-send:hover {
  opacity: 0.8;
}

.escolha-icones {
  background-color: #efefef;
  display: inline-block;
  padding: 30px;
  border-radius: 10px;
  cursor: pointer;
}

.icones-para-selecionar>i {
  padding: 10px;
  font-size: 50px;
  border-radius: 10px;
}

.icones-para-selecionar>i:hover {
  background-color: #efefef;
  cursor: pointer;
}

.icone-select {
  text-align: center;
  margin-top: 30px;
  color: #44bb52;
}

.icone-select>div {
  display: inline-block;
  background-color: #44bb52;
  color: #fff;
  padding: 10px;
  border-radius: 10px;
}

.btn-cadastro-parametros {
  background: #44bb52;
  color: white;
  border-Radius: 5px;
  padding: 5px 15px
}

.btn-cadastro-parametros:hover {
  background-color: #9be2a3;
}


.table-overflow {
  overflow-y: scroll;
  height: 200px;
  margin-bottom: 40px;
}

.image-monitorado {
  width: 45px;
  height: 45px;
  border: 2px solid #979699;
  border-radius: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.image-monitorado:hover {
  cursor: pointer;
  opacity: 0.5;
}

.sem-image-monitorado {
  width: 45px;
  height: 45px;
  border: 2px solid #979699;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.sem-image-monitorado img {
  max-width: 100%; 
  max-height: 100%;
  display: block;
  transform-origin: center center;
  transform: scale(2.5);
}

.sem-image-monitorado:hover img {
  cursor: pointer;
  opacity: 0.5;
}

#image-modal-wrapper {
  display: flex;
}

#image-modal-wrapper .image-modal-nav {
  background-color: rgba(0, 0, 0, 0.3);
  min-width: 50px;
  border-radius: 3px;
  height: 100%;
  transition: .15s;
  position: absolute;
  z-index: 100;
}

#image-modal-wrapper .image-modal-nav:first-child {
  left: 0;
  border-radius: 12px 0 0 12px;
}

#image-modal-wrapper .image-modal-nav:last-child {
  right: 0;
  border-radius: 0 12px 12px 0;
}

#image-modal-wrapper .image-modal-nav:hover {
  background-color: rgba(0, 0, 0, 0.45);
}

#image-modal-wrapper .image-modal-nav i {
  font-size: 28px;
  color: #fff;
}

.image-monitorado-modal {
  margin-top: 12vh;
  background: transparent !important;
  min-width: 64% !important;
}

.image-monitorado-modal .modal-content {
  box-shadow: none;
  background: transparent;
  border: none;
}

.image-monitorado-modal #image-modal-content {
  position: relative;
  cursor: default;
  width: 100%;
  min-height: 60vh;
  display: flex;
  background-color: #fff;
  border-radius: 12px;
  justify-content: center;
  overflow: hidden;
}

#image-modal-content img {
  height: 64vh;
  user-select: none;
  background-color: #fff;
}

.image-monitorado-modal:hover {
  cursor: pointer;
}

.automacao-alerta-condicao, .intervalo-item {
  background-color: #fff;
  padding: 4px 0 20px 0;
  margin: 0 4px;
  margin-bottom: 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  position: relative;
}

.automacao-alerta-condicao .hdv-automacao-delete-button,
.intervalo-item .hdv-automacao-delete-button {
  position: absolute;
  width: fit-content;
  height: fit-content;
  z-index: 100;
  transform: scale(0.9);
  margin: 0 !important;
  border-radius: 32px !important;
  padding: 4px 4px !important;
  right: -6px;
  top: -6px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.34);
}

.afira-table .hdv-automacao-delete-button i {
  font-size: 21px !important;
  padding: 0px !important;
  height: 10px;
  line-height: 10px;
  margin: 0 !important;
  width: 20px;
}

.form-flex {
  display: flex;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  padding-top: 15px;
}

.evento-detail {
  margin-bottom: 6px;
}

.evento-detail>div {
  margin-left: 8px;
}

.evento-detail summary {
  padding: 7px 8px;
  background-color: #f7f7f7;
  border-radius: 8px;
}

.evento-detail summary:hover {
  filter: brightness(0.95);
}

.modal-footer .btn, .aviso-feedback .btn {
  padding: 12px 20px;
  border-radius: 8px;
  font-weight: bold;
  min-width: 80px;
}

.modal-footer .btn-success {
  padding: 12px 20px;
  background-color: #44bb52;
  border-radius: 8px;
  font-weight: bold;
  min-width: 80px;
}

.modal-footer .btn-danger {
  padding: 12px 20px;
  background-color: rgb(255, 55, 55);
  border-radius: 8px;
  font-weight: bold;
  min-width: 80px;
}

.background-modal {
  background-color: rgba(0, 0, 0, 0.25);
  padding: 100px;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10000;
}

.simulacao-input, .simulacao-select {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  margin-right: 10px;
  width: 100%;
}

.simulacao-input label, .simulacao-select label {
  margin: 0;
  border-radius: 8px 0 0 8px;
  display: flex;
  align-items: center;
  font-size: 12px;
}

.simulacao-input span, .simulacao-select span {
  background: #eaeaea;
  padding: 2px 4px;
  border-radius: 4px;
  margin-top: 5px;
  cursor: pointer;
  user-select: none;
}

.simulacao-input span:hover, .simulacao-select span:hover {
  filter: brightness(0.95);
}

.and-operation {
  position: absolute;
  z-index: 1;
  margin-top: -64px;
  background-color: transparent;
  height: 120px;
  width: 24px;
  border-top: 3px solid #b1b1b1;
  border-left: 3px solid #b1b1b1;
  border-bottom: 3px solid #b1b1b1;
  border-radius: 5px 0 0 5px;
  padding: 0 4px;
  left: -10px;
  display: flex;
  align-items: center;
}

.or-operation {
  width: 100%;
  position: relative;
  margin-bottom: 28px;
}

.or-operation::before {
  content: 'OU';
  position: absolute;
  background-color: #e6e6e6;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  line-height: 35px;
}

.and-operation span {
  position: absolute;
  background-color: rgb(215, 215, 215);
  left: -15px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

progress {
  -webkit-appearance: none;
  appearance: none;
}

progress::-webkit-progress-bar {
  border-radius: 5px;
  height: 10px;
}

progress::-webkit-progress-value {
  background-color: #44bb52;
  border-radius: 5px;
}

.progressMax::-webkit-progress-value {
  background-color: #e54a4a;
}

.grid-informacoes-dashboard {
  width: 100%;
  display: grid;
  grid-template-columns: 50% calc(50% - 15px);
  grid-row-gap: 15px;
  grid-column-gap: 15px;
}

.grid-informacoes-dashboard-card {
  height: 126px;
  border: 2px solid #e3e3e3;
  border-radius: 15px;
  color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.grid-informacoes-dashboard-card h5 {
  font-weight: bold;
}

.grid-informacoes-dashboard-card p {
  margin: 0px;
}

.grid-informacoes-dashboard i {
  font-size: 105px;
  opacity: 0.125;
  margin-right: 2px;
}

.checkbox-switch {
  height: 2px !important;
  position: relative;

  input[type="checkbox"] {
    visibility: hidden;
  }

  input[type="checkbox"]~span {
    width: 38px;
    height: 14px;
    display: block;
    background-color: #c3c3c3;
    position: absolute;
    top: 30px;
    border-radius: 20px;
    cursor: pointer;
  }

  input[type="checkbox"]:checked~span::after {
    background-color: rgb(65, 182, 94);
    transform: translateX(22px);
  }
  
  input[type="checkbox"]~span::after {
    content: '';
    position: absolute;
    transform: translateX(-4);
    top: -3px;
    z-index: 100;
    background-color: #747474;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    transition: transform .15s linear;
  }
}

.checkbox-switch .afira-select-cf {
  width: 100%;
  position: relative;
}

.afira-select-cf::before {
  content: "Parâmetro";
  font-size: 13px;
  position: absolute;
  color: #979797;
  top: -3px;
}

.afira-select-cf summary {
  height: 43px;
  border: 1px solid #cacaca;
  border-radius: 10px;
  list-style: none;
  margin-top: 18px;
  line-height: 42px;
  padding: 0 8px;
  font-size: 12px;
}

.afira-select-cf .afira-select-cf-content {
  width: calc(100% - 32px);
  position: absolute;
  background-color: #fff;
  user-select: none;
  box-shadow: rgb(236, 236, 236) 5px 5px 0px -1px;
  border-radius: 8px;
  border: 5px solid rgb(236, 236, 236);
  padding: 10px 18px 26px 18px;
  z-index: 1000;
}

.afira-select-cf .afira-select-cf-content .hdv-top15 {
  margin-top: 6px !important;
}

.afira-select-cf button {
  border-radius: 8px;
  background-color: #007bff;
  padding: 8px 16px;
  float: right;
  margin-top: 12px;
}

.gm-style-mtc-bbw {
  height: fit-content !important;
}

.navbar-light .navbar-toggler {
  display: none;
}

.container-detalhes-comunicacao {
  width: 300px;
  background-color: #fafafa;
  border-radius: 15px;
  border: 2px solid silver;
  padding: 20px;
}

.container-detalhes-comunicacao p {
  margin: 5px 0px;
}

.container-detalhes-comunicacao p span {
  padding: 5px 10px;
  margin: 0px 2px;
}

.rdrNextButton i {
  margin: 0 !important;
}

.fixed-user-message {
  position: fixed;
  width: calc(100% - 302px);
  height: 35px;
  top: 0px;
  z-index: 1000;
  display: flex;
  justify-content: center;
}

.box-user-message {
  background-color: #ff000091;
  color: white;
  align-content: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 0px 30px;
}

.box-user-message:hover {
  background-color: red;
  transition: 0.5s;
}

.box-user-message span {
  text-decoration: underline;
  cursor: pointer;
}

@keyframes tremor {
  0% {
    transform: rotate(-1deg) translateX(-1px);
  }
  25% {
    transform: rotate(1deg) translateX(1px);
  }
  50% {
    transform: rotate(-1deg) translateX(-1px);
  }
  75% {
    transform: rotate(1deg) translateX(1px);
  }
  100% {
    transform: rotate(-1deg) translateX(-1px);
  }
}

.default-marker-container {
  position: relative; 
  width: 35px; 
  height: 35px;
  top: calc(100% - 50px);
  border-radius: 30px; 
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border: 3px solid white;
  overflow: visible;
  box-shadow: 0px 0px 3px black;
}

.default-marker-triangle {
  position: absolute;
  bottom: -15px; 
  transform: translateX(-50%);
  width: 12px;
  height: 12px;
  transform: rotate(-45deg);
  border: 3px solid white;
  border-top-right-radius: 30px;
  box-shadow: 0px 0px 2px black;
}

.default-marker-status {
  width: 10px;
  height: 10px;
  border-radius: 30px;
  position: absolute;
  bottom: 74%;
  left: 67%;
}

.acessar-button {
  cursor: pointer;
}

.acessar-button:hover {
  opacity: 0.5;
}