body {
    background-color: #fdfdfd !important;
}

.marker-filter {
    width: 25%;
    position: relative;
}

.hdv-zera-padding-left-right-boostrap {
    margin-top: 0px !important;
    border-radius: 10px !important;
}

.fluid {
    margin-left: 250px;
    height: 100%;
    position: relative;
    top: -30px;
}

.hdv-app-container {
    height: -webkit-fill-available;
}

.hdv-body-app {
    width: 100%;
    margin-top: 0px !important;
    padding-left: 20px;
    padding-right: 20px;
    position: absolute;
    top: 0px;
    height: 100%;
}

.home-style {
    padding: 0px !important;
    border-radius: 10px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    height: 100%;
    top: -5px;
    position: relative;
}

.hdv-bloco-top-space {
    height: 100%
}

.hdv-conteudo {
    height: 100%;
}

.hdv-conteudo>.row {
    padding-top: 140px;
    height: 100%;
    margin-right: -20px;
    margin-left: -20px;
}

.leaflet-top {
    top: auto !important;
}

.leaflet-left {
    left: auto !important;
    right: 8px !important;
    bottom: 26px !important;
}

.map-marker-toggle {
    position: absolute;
    top: 190px;
    left: 6px;
    z-index: 9999;
    height: 112px;
    width: 100px;
}

.map-marker-toggle #toggle {
    border: none;
    list-style: none;
    padding: 6px 7px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.36);
    background-color: white;
    border-radius: 8px;
    position: relative;
    color: #424242;
    z-index: -1;
    left: 6px;
    bottom: -13px;
}

.map-marker-toggle #toggle:hover {
    cursor: pointer;
    opacity: 0.8;
}

.leaflet-control-container {
    button:first-child {
        border-radius: 2px 0 0 2px;
    }

    button:last-child {
        border-radius: 0 2px 2px 0;
    }

    button.selected {
        background-color: #e7e7e7 !important;
    }
}

.leaflet-control-custom:has(.fa-search) {
    border-radius: 3px !important;
}

.leaflet-control-zoom-in, .leaflet-control-zoom-out, .leaflet-control-custom {
    background-color: #fff !important;
    width: 36px !important;
    height: 36px !important;
    font-size: 24px !important;
}

.leaflet-control-custom {
    font-size: 12px !important;
    z-index: 9999;
    position: absolute;
    border-radius: 3px;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
    left: 12px;
    top: 12px;

    &:hover {
        opacity: 1 !important;
    }
}

.map-layer-ctrl {
    font-size: 12px !important;
    z-index: 500;
    position: absolute;
    border-radius: 3px !important;
    overflow: hidden;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
    top: 12px;
    left: auto;
    right: 12px;
    display: flex !important;
    background: #fff !important;
}

.map-layer-ctrl button {
    background-color: transparent !important;
    width: fit-content !important;
    padding: 6px 12px;
    border-radius: 3px !important;
}

.marker-toggle-list {
    background-color: #fff !important;
    border-radius: 8px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
    position: absolute;
    left: 3px;
    z-index: 1;
    top: 8px;
    padding: 0 0;
    width: 280px;
    height: 46dvh;

    input {
        margin-top: 6px;
        padding: 5px 8px;
        width: 220px !important;
        margin-left: 8px;
        border: 1px solid #ddd;
        height: 36px !important;
        border-radius: 4px;
    }

    ul {
        margin-top: 10px;
        overflow: auto;
        height: 86%;
        padding: 0 8px;
    }

    ul::-webkit-scrollbar-track {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.4) !important;
    }

    ul::-webkit-scrollbar {
        width: 5px;
        background-color: #37424a00;
    }

    ul::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 4px;
    }
}

.map-marker-toggle li {
    list-style: none;
    padding: 5px 6px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    user-select: none;

    &:hover {
        cursor: pointer;
        background-color: #f3f3f3;
        color: #3273d4;
    }

    .badge {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        margin-right: 4px;
    }
}

.marker-filter {
    display: inline-block;
    position: relative;
}

.marker-first-info {
    padding: 0px 9px 0px 0px;
}

.marker-mid-info {
    padding: 0px 9px;
}

.marker-last-info {
    padding: 0px 0px 0px 9px;
}

.hdv-bloco-informacao {
    background-color: transparent;
}

.hdv-bloco-informacao, .hdv-info-bloco {
    border-radius: 16px !important;
}

.top-map-degrade {
    background: white;
    height: 10px;
    position: absolute;
    width: 100%;
    box-shadow: 14px -12px 60px 10px rgba(255, 255, 255, 1), 14px -6px 12px 7px rgba(255, 255, 255, 1), 14px 8px 8px 12px rgba(255, 255, 255, 0.8);
    z-index: 9;
    top: 0px;
}

.hdv-top-menu-position {
    position: relative;
    top: 15px;
    z-index: 10;
}

.hdv-zera-padding-left-right-boostrap>h4 {
    margin-top: 20px;
}

.hdv-zera-padding-left-right-boostrap>div>h4 {
    margin-top: 20px;
}

.hdv-btn-forms {
    border-radius: 5px !important;
}

.react-tabs__tab--selected {
    background: #4d4d4d !important;
    border-color: var(--fonte_titulo_abas) !important;
    color: var(--fonte_menu_lateral) !important;
    border-radius: 5px 5px 0 0 !important;
}

.hdv-form-default>span {
    width: 100%;
    display: block;
    padding: 0px 10px;
    margin-top: 2px;
    border-radius: 5px;
}

.hdv-form-default>span[id^="validacao-"] {
    width: 100%;
    background-color: tomato !important;
    color: #fff;
}

.css-bg1rzq-control {
    height: 42px;
}

.css-1szy77t-control {
    height: 42px;
}

.css-bg1rzq-control>div:first-child {
    height: 42px;
}

.css-1szy77t-control>div:first-child {
    height: 42px;
}

.css-1g6gooi {
    margin: -2px !important
}

.css-g44opg {
    z-index: 9999 !important;
}

.dias-semana-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 5px;
    grid-row-gap: 0px;
    overflow-x: auto;
}

.dias-semana-grid label {
    display: flex;
    align-items: center;
}

.dia-sem {
    color: #222 !important;
    font-size: 13px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 4px 0px !important;
    height: 32px;
    border-radius: 4px;
    cursor: pointer;
    background-color: #e7e7e7;
}

.dia-sem.active {
    background-color: rgb(75, 199, 75);
    color: white !important;
    font-weight: bold;
}

.dia-sem input[type="checkbox"] {
    width: 20px !important;
    min-width: 20px;
    margin-right: 6px;
}

.hdv-bloco-informacao {
    background-color: #EEECEC;
}

.hdv-popover {
    position: relative;
}

.hdv-popover section {
    background-color: #fff;
    min-width: 200px;
    min-height: 60px;
    right: 0;
    border-radius: 4px;
    border: 1px solid #c0c0c0;
    margin-top: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
    overflow: hidden;
    z-index: 100;
    position: absolute;
    padding: 15px;
}

.hdv-popover section li {
    list-style: none;
    padding: 7px 6px;
    font-size: 14px;
    background-color: inherit;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.hdv-popover section i {
    font-size: 21px;
    margin-right: 6px;
}

.hdv-popover section li:hover {
    filter: brightness(0.92);
}

.hdv-popover-button {
    position: relative;
    list-style: none;
    padding: 8px;
    border-radius: 8px;
    background-color: #e6e6e6;
    border: none;
    height: 40px;
}

.hdv-popover-button:hover {
    filter: brightness(0.92);
}

.search-bar {
    position: relative;
}

.search-bar button {
    background: transparent;
}

.search-input-icon {
    position: absolute;
    left: 15px;
    top: 8px;
    font-size: 28px;
    color: #979699 !important;
}

.gray-primary {
    color: #979699 !important;
}

.gray-background {
    color: #eee !important;
}

.dropdown>.nav-link {
    display: flex;
}

.dropdown>.nav-link>.user-name {
    color: #979699 !important;
    font-weight: bold;
    align-items: center;
    display: flex;
    padding: 0px 10px;
}

.hdv-image-round {
    border: 3px solid #979699 !important;
}

.screen-menu-desc {
    display: flex;
}

.screen-menu-desc>div {
    display: flex;
    color: var(--fonte_menu_lateral);
    background: var(--fonte_titulo_abas);
    padding: 6px 8px;
    border-radius: 8px;
    margin-left: 10px;
}

.screen-menu-desc>h4 {
    color: var(--fonte_titulo_abas);
    font-weight: bold;
    display: flex;
    align-items: center;
}

.hdv-mapform>div#map {
    top: 0px !important;
}

.hdv-subtable-title {
    margin-top: 20px;
    margin-top: 10px;
    font-weight: bold;
    color: #979797;
}

.hdv-subtable {
    border: none !important;
}

.hdv-subtable th, .hdv-subtable td {
    border: none !important;
}

.hdv-subtable>thead {
    text-align: left !important;
    font-weight: bold;
    font-size: 13px;
    background-color: transparent;
}

.hdv-subtable thead tr td, .hdv-subtable thead tr th {
    color: #777 !important;
    background-color: #fff !important;
}

div#map {
    background-color: #f2f2f2;
}

.hdv-subtable>tbody {
    font-size: 12px;
}

.hdv-subtable tbody td {
    padding: 5px 10px !important;
    border: none !important;
}

.flex-col {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: baseline;
}

.btn-inline-resolve {
    margin-bottom: 4px;
}

.img-preview-photo {
    width: 100px;
    border-radius: 50px;
    object-fit: cover;
    height: 100px;
}

.report-table-pagination {
    display: flex;
    margin-bottom: 32px;
    justify-content: center;
    margin-top: 8px;
}

.report-table-pagination button {
    padding: 10px 24px;
    background-color: #e2e2e2;
    border-radius: 9px;
    height: 42px;
    font-size: 12px !important;
    font-weight: bold;
    color: #484848;
}

.report-table-pagination button:hover {
    filter: brightness(0.92);
}

.report-table-pagination select {
    padding: 10px 10px;
    height: 42px;
    background-color: #e2e2e2;
    border-radius: 9px !important;
    width: 120px;
    color: #404040;
    border-radius: 8px;
    margin-left: 12px;
    cursor: pointer;
}

.afira-table {
    margin-bottom: 8px !important;
    height: fit-content;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 4px;
    border-radius: 8px;

    .afira-table-checkbox {
        text-align: center;
        border: none !important;
    }

    thead th {
        color: #888;
        text-overflow: ellipsis;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
    }

    tbody tr {
        border: none !important;
        border-radius: 6px !important;
        overflow: hidden;
        align-content: center;
        position: relative;
    }

    th {
        border: none !important;
    }

    tr td {
        background-color: #f9f9f9;
        padding: 12px 10px;
        border: none;
        color: #333;
        max-width: 200px !important;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        vertical-align: middle;
        display: table-cell;
    }

    tbody tr:hover td {
        background-color: #f2f2f2 !important;
    }

    tr td:first-child {
        border-radius: 10px 0 0 10px;
    }

    tr td:last-child {
        border-radius: 0 10px 10px 0;
    }
}

.afira-table-content {
    overflow: auto;
    min-height: 495px;
}

.afira-table-loading {
    position: relative;
}

.afira-table-loading::after {
    content: attr(data-message);
    position: absolute;
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: contrast(60%);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    border-radius: 12px;
    color: #626262;
}

.afira-th {
    border-top: 2px solid transparent !important;
    border-bottom: 2px solid transparent !important;
    word-wrap: break-word;
}

.afira-th[data-hoverable="on"]:hover, .afira-th__sorted:hover {
    cursor: pointer;
    background-color: #f6f6f6;
}

.afira-th__sorted {
    color: var(--fonte_titulo_abas) !important;
    overflow: hidden;
}

.afira-th__sorted[data-sortmode="ASC"] {
    border-top: 2px solid var(--fonte_titulo_abas) !important;
}

.afira-th__sorted[data-sortmode="DESC"] {
    box-shadow: inset 0 -2px 0px var(--fonte_titulo_abas) !important;
}

.afira-th__sorted i {
    color: var(--fonte_titulo_abas) !important;
}

.afira-table.table-sm td {
    max-height: 20px !important;
    padding: 5px 8px !important;
}

.afira-table-parent {
    position: relative;
}

.afira-table-parent .hdv-popover summary:hover {
    background-color: #f5f5f5;
}

.afira-table-search {
    position: absolute;
    right: 0px;
    top: -50px;
}

.afira-table-search .hdv-popover {
    margin-left: 6px;
    cursor: pointer;
}

.afira-table-search .hdv-popover i {
    color: #444 !important;
}

.afira-table-options {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    margin-top: 10px;
}

.afira-table-options button {
    padding: 4px 10px;
    height: 40px;
    min-width: 170px;
    border-radius: 8px;
    background-color: #e6e6e6;
    font-weight: bold;
    color: #686868;
}

.afira-table-options button:disabled {
    border: none !important;
}

.afira-table-options select {
    height: 40px;
}

.afira-table-options button:hover {
    cursor: pointer;
    filter: brightness(0.95);
}

.afira-table-options button:disabled {
    border: 2px solid #ddd;
}

.afira-table-options button:disabled:hover {
    cursor: not-allowed;
}

.afira-table-select select {
    background-color: #ededed;
    width: 110px;
    text-align: center;
}

#afira-table-config section {
    background-color: #f2f2f2;
    border: 3px solid rgb(236, 236, 236);
    box-shadow: rgb(236, 236, 236) 5px 5px 0px -1px;
    top: 50px;
    border-radius: 15px;
    color: #575757;
    cursor: default;
}

#afira-table-config[open]::before {
    background-color: #ececec;
    content: '';
    position: absolute;
    right: 5px;
    top: 25px;
    z-index: 100;
    width: 32px;
    height: 32px;
    clip-path: polygon(50% 50%, 0% 100%, 100% 100%);
}

#afira-table-config strong {
    user-select: none;
}

.icon-dashboard {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    line-height: 45px;
    font-size: 14px;

    color: white;
    text-align: center;
    transition: transform .08s;
}

.icon-dashboard:hover {
    transform: scale(1.1);
    filter: brightness(1.1);
}

.icon-dashboard::before {
    background-color: inherit;
    content: '';
    z-index: -1;
    position: absolute;
    left: 3px;
    top: 24px;
    width: 24px;
    height: 24px;
    -webkit-clip-path: polygon(50% 60%, 0 0, 100% 0);
    ;
    clip-path: polygon(50% 60%, 0 0, 100% 0);
    ;
}

details#afira-table-config section label {
    width: 100% !important;
    border-radius: 5px;
    padding: 5px 7px;
    margin: 0;
}

details#afira-table-config section label:hover {
    cursor: pointer;
    background: #dfdfdf;
}

.afira-stepper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
}

.afira-stepper .outline-btn {
    margin-bottom: 23px;
}

.afira-stepper .outline-btn:first-child {
    margin-right: 20px;
}

.afira-stepper .outline-btn:last-child {
    margin-left: 20px;
}

.afira-stepper-line {
    height: 3px;
    width: 100%;
    margin-bottom: 35px;
    border-radius: 8px;
    filter: brightness(1.5);
}

.afira-step {
    margin-top: 5px;
    padding: 4px 8px;
    text-align: center;
    position: relative;
    cursor: pointer;
}

.afira-step span {
    opacity: 0.9;
    font-size: 13px;
    user-select: none;
    margin-left: 2px;
}

.afira-step:hover span {
    color: var(--fonte_titulo_abas);
    cursor: pointer;
}

.afira-step::after {
    content: "";
    top: -20px;
    left: 33%;
    position: absolute;
    width: 22px;
    height: 22px;
    padding: 2px;
    cursor: pointer;
    color: white;
    font-weight: bold;
    border-radius: 32px;
    background-color: #767676;
    content: attr(title);
}

.afira-step[data-active-step="on"]::after {
    background-color: var(--fonte_titulo_abas) !important;
    box-shadow: 0 0 0 1px var(--fonte_titulo_abas), 0 0 0 3px inset var(--fonte_titulo_abas);
}

.icon-button {
    margin: 0 2px;
    background-color: #dedede !important;
    border-radius: 6px;
}

.outline-btn {
    background-color: #207fcc;
    font-weight: bold;
    color: #fff !important;
    border: none !important;
    cursor: pointer;
    display: flex;
    padding: 5px 5px;
    border-radius: 32px;
    justify-content: center;
}

.outline-btn:hover {
    filter: brightness(0.95);
}

.outline-btn:disabled {
    border: none !important;
    background-color: #dfdede !important;
    color: #333 !important;
    cursor: default;
}

.afira-time-picker {
    position: relative;
}

.afira-time-picker summary {
    border: 1px solid #dfdfdf;
    height: 43px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    font-size: 13px !important;
    padding: 0 12px;
}

.afira-time-picker #afira-time-menu {
    display: flex;
    position: absolute;
    top: 45px;
    background-color: #ffffff;
    border-radius: 6px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
    z-index: 99999;
}

.afira-time-picker #afira-time-menu span {
    color: #444;
    font-size: 14px;
    padding: 6px 14px;
    user-select: none;
    border-radius: 6px;
    text-align: center;
    width: 56px;
}

.afira-time-picker #afira-time-menu span:hover {
    background-color: #dbdbdb;
    cursor: pointer;
}

.afira-time-picker #afira-time-menu div {
    display: flex;
    flex-direction: column;
    height: 200px;
    overflow-y: scroll;
    padding: 3px 0;
    margin: 0 3px;
}

.afira-time-picker[open] summary::before {
    background-color: rgba(0, 0, 0, 0.0);
    content: '';
    width: 100vw;
    height: 100vh;
    position: fixed;
    cursor: default;
    z-index: 9999;
    left: 0;
    top: 0;
}

.contato-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contato-item p {
    margin: 0;
    font-size: 13px;
}

.contato-item i {
    border-radius: 50%;
    padding: 1px;
    transform: scale(.8);
    color: #333;
}

.automacao-simulacao, .automacao-resumo {
    padding: 12px 14px;
}

.automacao-resumo p {
    font-size: 13.5px;
    margin: 4px 0;
}

.custom-hr {
    display: block;
    height: 18px;
    margin-top: 16px !important;
    width: 100%;
    text-align: center;
    color: #333;
    position: relative;
    z-index: 10;
}

.custom-hr::before {
    content: attr(data-text);
    position: absolute;
    height: 20px;
    background-color: white;
    z-index: 0;
}

.custom-hr::after {
    content: '';
    background-color: #d0d0d0;
    position: absolute;
    width: 100%;
    height: 2px;
    top: 9px;
    left: 0;
    z-index: -1;
}

.status-comandos {
    display: flex;
    width: 100%;
    overflow-x: auto;
    margin-bottom: 20px;
}

.status-comandos .comandos-btn-editar {
    margin-right: 10px;
    min-width: 200px;
}

.compact-btn {
    background: transparent !important;
    height: fit-content !important;
    font-size: 13px !important;
    padding: 4px !important;
}

.compact-btn:hover {
    filter: brightness(1.0) !important;
    filter: saturate(1.3) !important;
    text-decoration: underline;
}

.log-json-tree ul {
    border-radius: 8px;
    margin: 6px 0 !important;
    padding: 0px 2px !important;
}

.last-report-span {
    color: #fff;
    padding: 1px 6px;
    border-radius: 12px;
    background-color: transparent;
}

.dropdown-params {
    top: -16px !important;
}

.search-input-toggle-off {
    width: 0;
    height: 0;
    visibility: hidden;
}

.search-input-toggle-on {
    width: 300px;
    background-color: #e6e6e6;
    border-radius: 8px 0 0 8px;
    transform: translateX(12px);
    padding-right: 12px;
    transition: width .2s;
}

.search-input-toggle-on input {
    height: 40px !important;
}

@keyframes input_anim {
    0% {
        width: 0px
    }

    100% {
        width: 300px
    }
}

.search-input-toggle-on input, .search-input-toggle-on button {
    border: none;
    background: transparent !important;
}

.hdv-login-loading {
    position: relative;
    height: 30px !important;
    width: 30px !important;
    top: 0;
    padding: 0;
}

.alert-details .badge {
    font-size: 12px;
    margin-bottom: 2px !important;
}

.alert-details textarea {
    font-size: 14px !important;
    color: #222222;
}

.alert-condition {
    margin-bottom: 20px;
}

.alert-condition span {
    margin-right: 8px;
    padding: 4px 6px;
    border-radius: 4px;
    background-color: #ececec;
}

.alert-condition-group {
    margin-top: 15px;
    width: 100%;
}

.alert-condition-group strong {
    position: absolute;
    margin-top: -17px;
    user-select: none;
    text-align: center;
    left: -2px;
}

.alert-condition-group strong::after {
    content: '';
    position: absolute;
    left: -8px;
    bottom: -10px;
    width: 26px;
    height: 41px;
    border-top: 2px solid #cacaca;
    border-bottom: 2px solid #cacaca;
    border-left: 2px solid #cacaca;
}

::-webkit-scrollbar {
    width: 5px;
    height: 7px;
}

::-webkit-scrollbar-track {
    background: #EEECEC;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #8F8F8F;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #8F8F8F;
}

.modal-image-subtitle-container {
    position: absolute;
    height: 30px;
    width: calc(100% - 30px);
    top: calc(100% - 45px);
    display: flex;
    justify-content: center;
}

.modal-image-subtitle-container div {
    color: white;
    font-size: 14px;
    margin: 0;
    background-color: black;
    opacity: 0.7;
    padding: 5px 20px 5px 20px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    max-width: 843px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.modal-image-button-add {
    margin: 2px 13px 0 0;
    color: #dadada;
    font-size: 0.8rem;
    font-weight: bold;
    background-color: transparent;
    z-index: 999;
    position: absolute;
    top: 1;
    right: 0;
    display: inline-block;
    padding: 0 3px;
    text-decoration: none;
}

.modal-image-button-add:hover {
    cursor: pointer;
    color: #fff;
    text-decoration: none;
    border-bottom: 3px solid #fff;
    transition: ease-in-out 0.1s;
}
