html, body, #root, .hdv-maxheight{
    height: 100%;
}

.body-page{
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: -moz-linear-gradient(to right, #0b4f87 50%, transparent 50%);
    background: -webkit-linear-gradient(to right, #0b4f87 50%, transparent 50%);
    background: linear-gradient(to right, #0b4f87 50%, transparent 50%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#61abe8', endColorstr='#2f577b',GradientType=0 );
}

.container-login{
    display: flex;
    justify-content: space-between;
    text-align: center;
    background-color:#0b4f87;
    width: 1050px;
    height: 520px;
    border-radius: 30px;
    box-shadow: 0px 0px 7px 0px #0e0e0e38;
}

.login-logo-sociais {
    position: relative;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.logomarca{
    position: absolute;
    /*height: 200px;*/
}

.subtitulo-logo{
    color: #aaa;
    font-weight: 300;
}

.texto-rodape{
    position: absolute;
    text-align: end;
    font-size: 12px;
    color: #aaa;
    left: calc(100% - 103px);
    top: calc(100% - 115px);
}

.sociais{
    position: absolute;
    top: calc(100% - 32px);
    left: calc(100% - 113px);
}

.sociais i:hover {
    cursor: pointer !important;
    opacity: 0.8;
}

.facebook-social{
    background: #1c68a7;
}

.lkdn-social{
    background: #3b93dc;
}

.tube-social{
    background: red;
}

.fa-white {
    width: 25px;
    height: 25px;
    font-size: 24px;
    border-radius: 5px;
    color: #fff;
    margin-left: 10px;
}

.titulo {
    font-size: 30px;
    font-weight: bold;
}

.form-login{
    background-color: #fff;
    width: 50%;
    height: 100%;
    border-top-right-radius: 28px;
    border-bottom-right-radius: 28px;
    padding: 50px;
}

.form-login form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: fit-content;
    margin-top: 70px;
}

.form-login input[type="text"], input[type="password"] {
    width: 100%;
    padding: 30px 0px 29px 30px;
    background-color: #F4F4F4;
    border: 1px solid transparent;
    outline: none;
    color: #575757;
    height: 40px;
    border-radius: 15px;
    margin: 5px 0px;
}

.container-login-password {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.container-login-password .password {
    width: 100%;
}

.container-login-password .see-password {
    position: absolute;
    left: calc(100% - 40px);
}

.container-login-password .see-password i {
    font-size: 28px;
}

.container-login-password .see-password:hover {
    cursor: pointer;
    opacity: 0.8;
}

.btn-login{
    background-color: #44bb52;
    width: 100%;
    color: white;
    padding: 21px;
    border-radius: 15px;
    margin: 25px 0px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-login:hover{
    opacity: 0.8;
    cursor: pointer;
}

.esqueci-minha-senha {
    margin: 10px 0px;
}

.esqueci-minha-senha a {
    color: #575757;
    font-weight: bold;
}

.esqueci-minha-senha a:hover {
    text-decoration: none;
    cursor: pointer;
    opacity: 0.8;
}

.permancer-label{
    color: #575757;
    font-weight: 700;
    font-size: 12px;
    margin-left: 10px;
    margin-bottom: 0px;
}
.mensagem-erro{
    position: absolute;
    left: 50%;
    text-align: center;
    background-color: #ce4444;
    width: 354px;
    height: 125px;
    margin-left: -195px;
    margin-top: -400px;
    border-radius: 5px;
    color: white;
    padding: 17px;
    z-index: 10;
}

.mensagem-icone{
    font-size: 48px;
    width: 48px;
}

input[type="checkbox"] {
    appearance:none;
    width:40px;
    height:16px;
    border:1px solid #aaa;
    border-radius:2px;
    background:#630b0b;
    position:relative;
    display:inline-block;
    overflow:hidden;
    vertical-align:middle;
    transition: background 0.3s;
    box-sizing:border-box;
    outline: none;
}

input[type="checkbox"]:checked{
    background:#44bb52;
    border-color:#44bb52;
}

.imagem-titulo {
    display: none;
}

@media screen and (max-width: 600px) {
    .body-page {
        padding: 0px 15px;
    }

    .container-login {
        width: 500px;
    }

    .login-logo-sociais{
        display: none;
    }

    .form-login {
        border-radius: 28px;
        width: 100%;
    }

    .form-login form {
        margin-top: 30px;
    }

    .titulo {
        display: none;
    }

    .imagem-titulo {
        display: block;
    }
}