.hdv-barsuper {
    background-color: rgba(238, 236, 236, 0.78);
    backdrop-filter: blur(20px);
    height: 50px;
    text-align: center;
    box-shadow: 1px 2px 8px -1px rgba(0,0,0,0.19);
    z-index: 1000;
    position: sticky;
    display: flex !important;
    justify-content: space-between !important;
    padding: 0 20px;
    top: 0px;
    /*border-radius: 5px;
    margin-left: 1px;
    margin-right: 1px;*/
}

.hdv-barsuper-default {
    color: #333 !important;
    background-color: #fff !important;
    padding: 20px 8px !important;
    border-radius: 8px;
    margin: 5px 0;
    position: relative;
}

.hdv-barsuper-default::after {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -6px;
    content: '';
    border-bottom: 2px solid #e4e4e4;
}

.hdv-barsuper-default:disabled {
    color: white !important;
}

.hdv-barsuper-default i, .hdv-barsuper-delete i {
    margin-right: 8px;
}

.hdv-barsuper-button {
    padding: 5px 10px;
    height: 37px;
    border-radius: 8px !important;
    color: #fff;
    box-shadow: none !important;
    user-select: none;
    cursor: pointer;
}

.hdv-barsuper-default:hover, .hdv-barsuper-button:hover {
    filter: brightness(0.92);
}

.hdv-popup-button {
    width: 100%;
    height: 36px;
    display: flex !important;
    justify-content: flex-start;
    align-items: center !important;
    color: white;
    font-weight: bold;
    user-select: none;
}

.hdv-popup-button:hover {
    filter: brightness(0.92);
}

.hdv-barsuper-show {
    background-color: #1b73c5f5;
    margin-left: 5px;
}

.hdv-barsuper-show:hover {
    background-color: #3790e2d6;
}

.hdv-barsuper-back {
    background-color: #fff;
    border: 1px solid #444;
    color: #444;
    border-radius: 8px;
    min-width: 150px;
}

.hdv-barsuper-back i {
    margin-right: 5px;
}

.hdv-barsuper-slide {
    background-color: #f3ac29;
    justify-content: center;
    border-radius: 8px;
}

.hdv-barsuper-delete, .hdv-barsuper-share {
    background-color: #fff;
    border-radius: 8px;
    color: rgb(219, 83, 83);
    padding: 20px 8px !important;
    font-weight: bold;
}

.hdv-barsuper-share {
    background-color: #4b85e3f5;
    margin-bottom: 10px;
}

.hdv-barsuper-new {
    background-color: #1bc57ef5;
    justify-content: center;
    border-radius: 8px;
    margin-right: 5px;
}

.hdv-barsuper-update {
    background-color: #1b5cc5f5;
    margin-left: 5px;
    border-radius: 8px;
}

.hdv-barsuper-update:hover {
    background-color: #3d71c5f5;
}

.hdv-barsuper-select#header {
    height: 35px;
}

.hdv-barsuper-select {
    padding: 0 10px;
    height: 43px;
    border-radius: 8px;
    border: 1px solid #dfdfdf !important;
    width: 100%;
    background-color: #f2f2f2;
}

.hdv-barsuper-input {
    padding: 10px;
    border-radius: 5px;
    width: 100% !important;
    margin-top: 5px;
    background-color: #f2f2f2;
    border: none;
}

.hdv-barsuper-label {
    display: block;
    margin-bottom: 0px;
}

.hdv-sup-label {
    display: flex;
    align-items: center;
    width: fit-content;
    margin-right: 8px;
    padding: 2px 4px;
}

.hdv-barsuper-label-margin {
    margin-top: 20px;
}

label.hdv-barsuper-label:has(input[type="checkbox"]) {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
}

label.hdv-barsuper-label:has(input[type="checkbox"]) input {
    margin: 15px 0 12px 12px;
    transform: scale(1.25);
    padding: 0;
}

label.hdv-barsuper-label:has(input[type="checkbox"])::before {
    content: '';
    z-index: -1;
    width: 100%;
    position: absolute;
    left: 0;
    top: 24px;
    height: 43px;
    border: 1px solid #dbdbdb;
    border-radius: 8px;
    background-color: #f2f2f2 !important;
}

.hdv-barsuper-bottom {
    padding-bottom: 6px;
    text-align: center;
}

.hdv-widget-config-fieldset {
    padding: 10px;
    margin: 0px;
    border: 1px solid rgb(190, 190, 190);
    margin-top: 10px;
    border-radius: 8px;
}

.hdv-widget-config-fieldset-legend {
    font-size: 14px;
    width: auto;
}

.hdv-super-popup {
    position: relative;
}

.hdv-super-popup summary {
    list-style: none;
    background-color: #e6e6e6;
    padding: 2px 20px;
    border-radius: 6px;
    margin-top: 7px;
}

.hdv-super-popup[open]::before {
    content: '';
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: transparent;
}

.hdv-super-popup[open] section {
    position: absolute;
    margin-top: 4px;
    background-color: #fff !important;
    width: 340px;
    z-index: 400;
    padding: 16px 20px 30px 20px;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: stretch !important;
    border-radius: 7px;
    height: fit-content;
    float: right;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.34);
}

.hdv-bodysuper {
    min-height: calc(100vh - 50px);
    margin: 0 10px;
    width: calc(100% - 22px) !important;
    left: -20px;
}

.hdv-bodysuper .hdv-bloco-informacao, .hdv-bodysuper .hdv-info-bloco {
    border-radius: 5px !important;
}

.layout-inlineflex {
    display: inline-flex;
    margin-top: 10px;
    text-align: center;
}

.layout-inlineflex i {
    margin-right: 9px;
}

.icon-big {
    font-size: 60px;
    margin-right: 3px;
}

.value-super {
    font-size: 40px;
}

.black-txt {
    color: #000;
}

.white-txt {
    color: #fff;
    background-color: tomato !;
}

.green-txt {
    color: #1bc57ef5;
}

.red-txt {
    color: tomato;
}

.blue-txt {
    color: #1b73c5f5;
}

.yellow-txt {
    color: #ffb100f5;
}

.background-yellow {
    background-color: #ffb100f5;
    border-top:   1px solid #df9e04f5;
    border-right: 1px solid #df9e04f5;
}

.background-red {
    background-color: tomato !important;
    border-top:   1px solid #c33737 !important;
    border-bottom: 1px solid #c33737 !important;
}

@keyframes blink {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
}

.bar-info {
    background-color: darkgrey;
    bottom: 0px;
    position: absolute;
    width: 100%;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.bar-label {
    margin: 0px;
    position: relative;
    display: inline-grid;
    font-size: 11px;
    color: #fff;
    padding: 4px 0px 2px 0px;
    width: 33%;
}

.bar-label-green {
    background-color: #1bc57ef5;
    border-bottom-left-radius: 5px;
}

.bar-label-yellow {
    background-color: #ffb100f5;
}

.bar-label-red {
    width: 34%;
    background-color: tomato;
    border-bottom-right-radius: 5px;
}

.bar-value {
    position: absolute;
    text-align: center;
    display: flex;
    justify-content: center;
    opacity: 0.9;
    top: 35%;
    width: 100%;
}

.box-gauge {
    height: 100%;
    position: relative;
    top: -41px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.box-gauge-margintop {
    margin-top: 41px;
}

.hdv-bullet-well {
    height: 100%;
    position: relative;
    top: -41px;
}

.hdv-bullet-well-box {
    height: 100%;
    padding: 51px 10px 10px 10px;
}

.hdv-bullet-well-terrain {
    height: 100%;
    display: inline-flex;
    width: 70%;
}

.hdv-bullet-well-draw {
    width: 100%;
    height: 100%;
    background-color: #dea077;
    background-image: repeating-linear-gradient(#6dc16d 0%, #956b4f 8%, #dea077 100%);
    align-content: center;
    justify-content: center;
    display: flex;
    border-top: 26px solid #6dc16d;
}

.hdv-bullet-well-grade {
    width: 5%;
    height: 100%;
    display: inline-flex;
    margin-left: 5px;
    border-bottom: 1px dotted silver;
    border-right: 1px dotted silver;
    border-top: 1px dotted silver;
}

.hdv-bullet-well-grade-item {
    display: block;
    position: absolute;
    font-size: 9px;
    margin-left: 10px;
    color: silver;
}

.hdv-bullet-well-water {
    /*background-color: #88d0e8;*/
    background-image: repeating-linear-gradient(#88d0e8 0%, #78b8ce 5%, #70a9bd 100%);
    width: 45px;
    height: 488px;
    position: absolute;
    margin: 0 auto;
    bottom: 0;
    margin-bottom: 10px;
}

.hdv-bullet-well-level {
    /*background-color: #24b5e4;*/
    background-image: repeating-linear-gradient(#24b5e4 0%, #209cc5 5%, #2295bb 100%);
    width: 45px;
    height: 418px;
    position: absolute;
    margin: 0 auto;
    bottom: 0;
}

.hdv-water-pump-point {
    height: 24px;
    width: 56px;
    background-color: #616161;
    position: absolute;
    transform: translate(5px, 0px);
}

.hdv-water-pump-desc {
    font-size: 10px;
    color: white;
    margin-left: 1px;
}

.hdv-water-pump-desc-levels {
    top: -14px;
    position: absolute;
}

.hdv-water-pump-fan {
    color: tomato;
    font-size: 20px;
    position: absolute;
    top: 2px;
    left: 34px;
}

.hdv-image-widget {
    height: 100%;
    background-size: cover;
    background-position-y: 41px;
    background-repeat: no-repeat;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.hdv-bullet-well-static-point {
    width: 83px;
    height: 2px;
    position: absolute;
    background: #2461d0;
}

.hdv-bullet-well-dynamic-point {
    width: 83px;
    height: 2px;
    position: absolute;
    background: #5a7cb9;
}

.hdv-close-widget {
    font-size: 20px;
    color: red;
    display: inline-block;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 99999
}

.hdv-overlay {
    position: fixed;
    background-color: black;
    width: 100%;
    height: 100%;
    z-index: 99999;
    top: 0;
    opacity: 0.7;
}

.hdv-icons-list {
    position: fixed;
    background: black;
    top: 30%;
    margin: 0 auto;
    z-index: 9999999;
    text-align: center;
    align-items: center;
    align-content: center;
    padding: 20px 5px 5px 5px;
    width: 100%;
}

.hdv-icons-list h2 {
    color: #fff;
    font-weight: bold;
}

.hdv-icons-list ul {
    margin: 30px;
    padding: 0px;
}

.hdv-icons-list ul li {
    padding: 10px;
    display: inline-block;
}

.hdv-icons-list i {
    color: #fff;
    font-size: 30px;
}

.hdv-icons-list i:hover {
    color: #ffff00;
    cursor: pointer;
}

.hdv-metric-text {
    z-index: 1000;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    top: 52px;
    font-weight: bold;
    opacity: 0.75;
    right: 12px;
}

.hdv-water-pump-fan-none {
    color: #ffffff;
}

.hdv-vazao-detalhe {
    position: relative;
    top: 5px;
    width: 85px;
}

.hdv-water-pump-fan-on {
    color: #6dc16d;
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

.hdv-widget-popup {
    height: fit-content !important;
}

.hdv-widget-popup label {
    width: 100%;
}

.hdv-widget-popup button {
    width: 130px;
}

.hdv-widget-popup input {
    padding: 4px 6px;
    font-size: 13px !important;
}

.div-sample {
    position: relative;
}

.hdv-widget-options {
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 5px;
    height: 41px;
    width: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px !important;
    z-index: 3000;
}

.hdv-widget-options:hover {
    cursor: pointer;
    opacity: 0.5;
}

.react-resizable-handle {
    bottom: 5px !important;
    right: 5px !important;
}

.react-resizable-handle::after {
    content: '↔';
    position: absolute;
    cursor: move;
    bottom: -2px;
    right: 9px;
    font-size: 28px;
}

.hdv-links {
    min-height: 370px;
    max-height: 600px;
    overflow-y: auto;
    clear: both;
}

.hdv-links .hdv-popover-button {
    background-color: transparent;
    border-radius: 5px;
    padding: 2px 4px;
    width: fit-content;
    float: right;
}

.hdv-links .hdv-popover-button:hover {
    background-color: #e4e4e4;
}

.hdv-links section>button {
    padding: 8px 6px;
    min-width: 80px;
    background-color: #fafafa;
}

.hdv-links section>button:hover {
    filter: brightness(0.92);
}

.hdv-links #delete {
    background-color: rgb(209, 95, 75);
    color: white;
}

.hdv-chart-bloco-reactgridlayout i.fa-square-full[data-status="Atualizado"] {
    background-color: #3e9448;
    color: transparent;
    z-index: 99999;
    position: relative;
}

.hdv-chart-bloco-reactgridlayout i.fa-square-full[data-status="Atrasado"] {
    background-color: tomato;
    color: transparent;
    z-index: 99999;
    position: relative;
}

.hdv-chart-bloco-reactgridlayout i.fa-square-full {
    border-radius: 16px;
    width: 13px;
    height: 13px;
    margin-right: 9px;
    margin-left: 4px;
    cursor: pointer;
}

.hdv-chart-bloco-reactgridlayout i.fa-square-full::after {
    position: absolute;
    top: -12px;
    left: -14px;
    content: '';
    background-color: transparent;
    width: 38px;
    height: 40px;
}

.hdv-chart-bloco-reactgridlayout .hdv-top-bar-hidden {
    z-index: 9999;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(20px);
    top: 30px;
    left: 0;
    width: 100%;
    min-width: 240px;
    border-radius: 6px;
    color: #000;
    position: absolute;
    visibility: 0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
    padding: 4px 12px;
    display: none;
    user-select: none;
}

.hdv-chart-bloco-reactgridlayout i.fa-square-full ~ .hdv-top-bar-hidden p {
    margin: 1px 0 !important;
    font-size: 14px;
    opacity: 0.85;
    line-height: 18px;
}

.hdv-chart-bloco-reactgridlayout i.fa-square-full:hover ~ .hdv-top-bar-hidden,
.hdv-chart-bloco-reactgridlayout i.fa-square-full ~ .hdv-top-bar-hidden:hover {
    display: block;
}

.sup-icone {
    position: relative;
}

.sup-icone span {
    position: absolute;
    transform: scale(1.4);
    width: 150px;
    top: 13px;
    left: 36px;
    background-color: aqua;
    background-color: #eee;
}

.spinning {
    animation: hdv_spin 5000ms linear infinite;
}

@keyframes hdv_spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

.hdv-bodysuper .comandos-parametros label {
    width: 100%;
}

.box-gauge-margintop .comandos-flex {
    min-width: 250px !important;
    max-width: 90% !important;
}

.hdv-sup-unity {
    position: absolute;
    right: 8px;
    opacity: 0.7;
    top: 45px;
}

.hdv-bodysuper .tabs-style {
    margin-top: 0px;
}

#selecionar_planta {
    position: relative;
    background-color: #1bc57ef5;
}

#imagem_planta {
    opacity: 0;
    position: absolute;
    left: 0px;
    width: 161.39px;
    height: 100%;
    top: 0px;
    border-radius: 8px !important;
}

#imagem_planta:hover {
    cursor: pointer;
}

#group-button-planta {
    margin-top: 6px;
    display: flex;
    justify-content: end;
    position: fixed;
    width: 450px;
    top: 0px;
    left: calc(100% - 450px);
    z-index: 1000;
}

#group-button-planta button {
    z-index: 1000;
}

.hdv-links .link-item {
    border-bottom: 2px solid #ededed;
}

.hdv-links .link-item td {
    vertical-align: middle;
}

.hdv-links thead th {
    border-bottom: 2px solid #ededed;
    padding-bottom: 12px !important;
}