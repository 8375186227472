.custom-div-icon {
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.custom-marker-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
  
.custom-marker {
    position: relative;
    width: 40px;
    height: 40px;
    background-color: var(--background-menu); 
    border-radius: 50%; 
    border: 2px solid white; 
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); 
}

.label-marker {
    position: absolute;
    width: 80px;
    height: 30px; 
    background: var(--background-menu);
    left: 85%;
    top: 9%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 0px 10px 10px 0px;
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    border-right: 2px solid white;
}
  
.custom-marker-gradient {
    width: 15px;
    height: 32px; 
    background: linear-gradient(to bottom, var(--background-menu), transparent);
}
  